export const RefBerklayDB = [
  "Aruppukottai Sri Jayavilas Limited",
  "Multiple Proponents",
  "JPMorgan Ventures Energy Corporation",
  "Multiple Proponents",
  "Inner Mongolia Xinjin Wind Power Co., Ltd.",
  "GVK Industries Limited",
  "Enerjisa Enerji Uretim A.S.",
  "Enerjisa Enerji Uretim A.S.",
  "T.C. Bursa Metropolitan Municipality (BMM)",
  "NISAN ELEKTROMEKANIK ENERJI SANAYI VE TICARET A.S.",
  "RHG Enerturk Enerji Uretim ve Tic. A.S.",
  "RHG Enerturk Enerji Uretim ve Tic. A.S.",
  "Triveni Engineering and Industries Limited (TEIL)",
  "RHG Enerturk Enerji Uretim ve Tic. A.S.",
  "RHG Enerturk Enerji Uretim ve Tic. A.S.",
  "RHG Enerturk Enerji Uretim ve Tic. A.S.",
  "RHG Enerturk Enerji Uretim ve Tic. A.S.",
  "MEM Enerji Elektrik Uretim San. Tic. A.S.",
  "Indian Farm Forestry Development Co-operative Limited",
  "CE Technology Berhad",
  "CEMEX Southeast LLC",
  "Simran Wind Project Private Ltd",
  "İçtaş Yenilenebilir Enerji Üretim ve Ticaret A.Ş",
  "Bhageshwari Paper Pvt Ltd",
  "Palace Solar Energy Private Limited.",
  "M/s D. J. Malpani",
  "M/s D. J. Malpani",
  "M/s Giriraj Enterprises",
  "M/s Giriraj Enterprises",
  "M/s. Kisan Veer Satara Sahakari Sakhar Karkhana",
  "Essel Mining & Industries Limited",
  "International Wood Corporation",
  "Colbun S.A.",
  "Colbun S.A.",
  "RIMA Industrial S/A",
  "Metrolina Greenhouses, Inc.",
  "Dak Psi Hydropower Investment and Development JSC",
  "Yunnan Xudong Phosphate Chemical Group Jinfeng Power Generation Co., Ltd.",
  "Hubei Shenzhou New Energy Power Generation Co.,",
  "Chifeng City Huifeng New Energy Co., Ltd.",
  "Keshiketeng County Huifeng New Energy Co., Ltd.",
  "Hillsborough County",
  "Hydrochina Guazhou Wind Power Co., Ltd",
  "Colbun S.A.",
  "Tata Power Renewable Energy Limited",
  "JSC Sawmill 25",
  "Inner Mongolia Xinjin Wind Power Co., Ltd.",
  "CE OAXACA CUATRO S. DE R.L. DE C.V.",
  "CE OAXACA TRES S. DE R.L. DE C.V.",
  "CE OAXACA DOS S. DE R.L. DE C.V.",
  "Guizhou Zhijn Ouhua Developent Co.Ltd.",
  "ReNew Wind Energy (Rajkot) Private Limited",
  "Lafarge Cement Syria",
  "Govt. of Madagascar, Direction Generale des Forets",
  "NASCSP",
  "Shuangyashan Longyuan Wind Power Co., Ltd.",
  "Yangbi Heihuijiang Electric Power Development Co.,",
  "Ovunc Enerji ve Elektrik Uretim A.S.",
  "Hegang Longyuan Wind Power Co., Ltd.",
  "Multiple Proponents",
  "CMPC Celulosa S.A.",
  "Lake Region State College",
  "Mikro-Tek Inc.",
  "CECIC Wind Power (Zhangbei) Yunwei Co., Ltd",
  "Guangxi Fangyuan Electric Power Co., Ltd.",
  "Vish Wind Infrastructure LLP",
  "Hidro-D Hidroelektrik Enerji Uretim A.S.",
  "Guizhou Qiannan Zhongshui Hydro Power Development",
  "Bethlehem Authority",
  "Kentilux S.A.",
  "Energie Eolienne du Maroc",
  "Multiple Proponents",
  "EKI Energy Services Limited",
  "Empresas Publicas de Medellin EPM ESP",
  "Cenay Elektrik Uretim Ins.San. ve Tic. Ltd",
  "AIDER",
  "Central Hidroeléctrica El Edén S.A.S. E.S.P.",
  "Ghodawat Industries (I) Pvt.Ltd.",
  "Ortitlan Limitada",
  "Patrícia Matos de Cunha LTDA",
  "African National Oil Corporation s.a.r.l.",
  "Multiple Proponents",
  "Vaayu (India) Power Corporation Private Limited",
  "Vish Wind Infrastructure LLP",
  "Multiple Proponents",
  "Multiple Proponents",
  "Energia Eolica de Honduras, S.A.",
  "Guangdong Yudean Zhanjiang Wind Power Co.Ltd",
  "Multiple Proponents",
  "Tiefa Coal Industry (Group) Co., Ltd.",
  "Multiple Proponents",
  "Wind World Wind Resources Development Private Limited",
  "co2balance UK",
  "Lican SpA",
  "Multiple Proponents",
  "EcoPlanet Bamboo Group",
  "Guizhou Tongzi River Hydropower Development Co., L",
  "Local Energy Alliance Partnership (LEAP)",
  "National Enterprises",
  "Laurelbrook Farm",
  "M/s MITCON Consultancy Services Ltd.",
  "Roha Dyechem Private Limited",
  "HOSDERE ENERJI A.S.",
  "Waa Solar Private limited",
  "Saisudhir Energy Limited",
  "Multiple Proponents",
  "Beijing Rural Well-off Economy &Technology",
  "Vish Wind Infrastructure LLP",
  "Vish Wind Infrastructure LLP",
  "Huadian Suzhou Biomass Power Industrial Co., Ltd",
  "Qian’an IW Power Co., Ltd, P.R.China",
  "Vitol SA",
  "JPMorgan Ventures Energy Corporation",
  "'SANKO Enerji Sanayi ve Ticaret A.Ş",
  "BTA Enerji Elektrik Üretim İnşaat",
  "Enercon (India) Limited",
  "DOMINICAN POWER PARTNERS, LDC",
  "AVKAL ENERJİ ÜRETİM VE TİCARET ANONİM ŞİRKETİ",
  "Turhal Enerji Uretim ve Ticaret A.S.",
  "Arsan Sogukpynar Elektrik Uretim A.S.",
  "Koprubapy Enerji Elektrik Uretim A.S.",
  "Kyy Enerji A.S.",
  "Tata Power Renewable Energy Limited",
  "Tektuð Elektrik Üretim A.Þ.",
  "Integral Coach Factory",
  "GENNEIA S.A.",
  "Multiple Proponents",
  "Multiple Proponents",
  "Mahati Hydro Power Projects Private Limited",
  "Multiple Proponents",
  "Kansas City Power and Light Greater Missouri",
  "ARCELIK A.S.",
  "Metareilá Association of the Suruí Indigenous P'pl",
  "Multiple Proponents",
  "Siento Energy Investment",
  "Vaayu (India) Power Corporation Private Limited",
  "NATIONAL GUZHEN BIO ENERGY CO.,LTD.",
  "Rainforest Project Management Limited.",
  "Gujarat State Fertilizers & Chemicals Ltd.",
  "Gujarat State Fertilizers & Chemicals Ltd.",
  "Carbon Offset Aggregation Co-operative BC",
  "National Bio Energy Co., Ltd",
  "Bilgin Güç Santralleri Enerji Üretim A.Ş.",
  "Inner Mongolia Guodian New Energy Co.,Ltd.",
  "Multiple Proponents",
  "KHATAU NARBHERAM & CO.",
  "KHATAU NARBHERAM & CO.",
  "EKI Energy Services Limited",
  "Lawrence Bishop",
  "Hindustan Zinc Limited",
  "Hindustan Zinc Limited",
  "Hindustan Zinc Limited",
  "Hindustan Zinc Limited",
  "Hindustan Zinc Limited",
  "Hindustan Zinc Limited",
  "M/s Mysore Mercantile Co. Limited",
  "Xinxiang Tianjie Bio-Power Generation Co., Ltd",
  "Proteak UNO S.A.B. de C.V.",
  "Ticket Soluções HDFGT S.A",
  "Datang Angli (Lingwu) New Energy Co., Ltd.",
  "Multiple Proponents",
  "Best Foods Limited",
  "Multiple Proponents",
  "Empresa Brasileira de Conservação de Florestas (EBCF)",
  "Guizhou Zhongshui Energy Co.Ltd.",
  "Barrick Chile Generacion Limitada",
  "Core Carbon Group ApS",
  "Algoma Highlands Conservancy",
  "Siddhayu Ayurvedic Research Foundation (P) Limited",
  "GURSU TEMIZ ENERJI URETIM A.S.",
  "Société de gestion de projets ECOTIERRA Inc.",
  "Agrawal Renewable Energy Private Limited",
  "Solarfield Energy Private Limited",
  "Saraff Energies Ltd.",
  "Staatsolie Maatschappij Suriname N.V.",
  "Gangadhar Narsingdas Agrawal (HUF)",
  "Liaoyuan Jingang Cement (Holding) Co., Ltd.",
  "Gillanders Arbuthnot & Co. Ltd",
  "Environmental Protection Bureau of Taipei County",
  "Beijing Shengdahuitang Carbon Management Co.,Ltd.",
  "NSL WInd Power Company Phoolwadi Private Limited",
  "Nagarjuna Agrichem Limited",
  "GEC Organics Corp.",
  "Daqing Oilfield Co., Ltd.",
  "Shri Bajrang Power and Ispat Ltd",
  "Multiple Proponents",
  "Empresas Publicas de Medellin S.A",
  "Hindustan Zinc Limited",
  "ARSAN ENERJI A.S.",
  "Indapur Dairy and Milk Products Limited",
  "Rajasthan Rajya Vidyut Utpadan Nigam Limited",
  "Celsia Colombia S.A. E.S.P.",
  "AKCAY HES ELEKTRYK URETYM A.S",
  "The Nature Conservancy",
  "Emergent Ventures India Private Limited",
  "Daan CGN Wind Power Co., Ltd",
  "Jilin CGN Wind Power Co., Ltd",
  "Guazhou Tianrun Windpower Co., Ltd.",
  "Guizhou Tengyuan Electric Power Development Co. Lt",
  "CGN Wind Energy Limited",
  "CGN Wind Power Co., Ltd.",
  "CGN Wind Power Co., Ltd.",
  "Xinjiang Jimunai CGN Wind Power Co., Ltd.",
  "Hebei Longyuan Wind Power Co., Ltd.",
  "M/S Usaka Hydro Powers Pvt Ltd",
  "Celulosa Arauco y Constitución S.A.",
  "Yishui Tangwangshan Wind Power Co., Ltd.",
  "Wendeng Zhangjiachan Wind Power Co., Ltd.",
  "Anqiu Taipingshan Wind Power Co., Ltd.",
  "Sinan Tengyuan Shuanghe Electric Power Co. Ltd.",
  "Mytrah Energy (India) Limited",
  "Bindu Vayu Urja Private Limited (BVUPL)",
  "Auto. Authority Electrical Mass Transit Sys - AATE",
  "Elestaş Elektrik Üretim Anonim Şirketi",
  "M/s Bindu Vayu Urja Private Limited",
  "Mytrah Energy (INDIA) Ltd.",
  "Gansu Longlin Hydropower Development Co., Ltd",
  "M/s Bindu Vayu Urja Private Limited",
  "Darenhes Elektrik Uretimi A.S.",
  "Bugoye Hydro Limited",
  "Neijiang Xingming Energy Co., Ltd.",
  "Menderes Geothermal Elektrik Uretim A.S",
  "Guohua Bayannaoer (Wulate Zhongqi) Wind Power Co.",
  "Gola Rainforest Conservation LG",
  "BioCarbon Partners",
  "Kishangarh Hi-tech Textile Park",
  "Guohua Bayannaoer (Wulate Zhongqi) Wind Power Co., Ltd",
  "Darenhes Elektrik Uretimi A.S.",
  "Celsia Colombia S.A. E.S.P.",
  "M/s Bindu Vayu Urja Private Limited",
  "Song Lam Construction and Investment Company Ltd",
  "POWERICA LIMITED",
  "JPMorgan Ventures Energy Corporation",
  "POWERICA LIMITED",
  "POWERICA LIMITED",
  "M/s. Emami Cement Limited.",
  "SSA INTERNATIONAL LIMITED",
  "Mytrah Vayu (Pennar) Private Limited",
  "The Wildlife Conservation Society (WCS)",
  "C-Quest Capital",
  "Sree Rayalaseema Hi-Strength Hypo Limited",
  "Multiple Proponents",
  "Xinjiang Bazhou Xinhua Hydropower Development Co.",
  "Multiple Proponents",
  "West Henan Xinhua Irrigation and Hydroelectricity",
  "Egenda Ege Enerji Uretim A.S",
  "Guizhou Haikang Marsh gas Material Trade Co,Ltd.",
  "Guizhou Black Carbon Energy Tech Prom & App Co. Lt",
  "Guizhou Black Carbon Energy Tech Prom & App Co. Lt",
  "Vi Agroforestry Programme",
  "Anhui Zhongxing Shouchuang Energy-saving Service",
  "NSL Eólica Limitada",
  "Ecosecurities Group Limited",
  "Multiple Proponents",
  "Longyuan Hydro Power Development in Congiang Count",
  "Jain Irrigation Systems Ltd.",
  "Egenda Ege Enerji Uretim A.S",
  "Yaylaköy RES Elektrik Üretim A.Ş.",
  "NACOBENA SAS",
  "Guizhou Black Carbon Energy Tech Prom & App Co. Lt",
  "Generadora Cantayus S.A.S. E.S.P.",
  "Visual Percept Solar Projects Pvt. Ltd. (VPSPPL)",
  "Menerji Elektrik Uretim Dag. Paz. San. Ve Tic. San",
  "Big Bloc Construction Limited",
  "Ozone Envirotech Private Limited",
  "Gansu Jieyuan Wind Power Co., Ltd.",
  "Energy Joburg RF (PTY) Limited",
  "Mahati Hydro Power Projects Private Limited",
  "Longyuan Inner Mongolia Wind Power Co., Ltd.",
  "Guizhou Black Carbon Energy Tech Prom & App Co. Lt",
  "Guizhou Black Carbon Energy Tech Prom & App Co. Lt",
  "Guizhou Black Carbon Energy Tech Prom & App Co. Lt",
  "Cogent Farming Business Systems Ltd",
  "CGN Solar Power Development Co., LTD",
  "CGN Solar Power Development Co., LTD",
  "CGN Solar Power Development Co., LTD",
  "Taicang Waste Incineration Cogeneration Co. Ltd",
  "CGN Enfinity (Dunhuang) Solar Energy Development",
  "3 Rocks Limited",
  "3 Rocks Limited",
  "3 Rocks Limited",
  "Bhoruka Power Corporation Limited",
  "GeeCee Ventures Limited",
  "Energy Management Centre - Kerala",
  "CLP Wind Farms (India) Private Limited",
  "AES Geo Energy OOD",
  "Pacific Hydro Chacayes S.A.",
  "Pequena Central Hidrelétrica Zé Tunin S.A.",
  "Lanco Kondapalli Power Limited",
  "Multiple Proponents",
  "Multiple Proponents",
  "Yunnan Longjiang Hydropower Project Development Co",
  "Energy Management Centre - Kerala",
  "Energy Management Centre - Kerala",
  "Energy Management Centre - Kerala",
  "Energy Management Centre - Kerala",
  "Energy Management Centre - Kerala",
  "INDIAN FARMERS FERTILISER COOPERATIVE LIMITED",
  "Energy Management Centre - Kerala",
  "Energy Management Centre - Kerala",
  "Energy Management Centre - Kerala",
  "Energy Management Centre - Kerala",
  "Energy Management Centre - Kerala",
  "Energy Management Centre - Kerala",
  "Energy Management Centre - Kerala",
  "Energy Management Centre - Kerala",
  "Energy Management Centre - Kerala",
  "Energy Management Centre - Kerala",
  "International Development Enterprises (India)",
  "Energy Management Centre - Kerala",
  "Energy Management Centre - Kerala",
  "Energy Management Centre - Kerala",
  "Energy Management Centre - Kerala",
  "Enerjisa Enerji Uretim A.S.",
  "Tasmanian Land Conservancy",
  "Multiple Proponents",
  "Enerjisa Enerji Uretim A.S.",
  "Multiple Proponents",
  "Cadonal S.A.",
  "Ind Power Limited",
  "Kanaka Management Services Private Limited",
  "Andhra Pradesh Power Generation Corporation Limited",
  "Vaibhavlaxmi Clean Energy LLP",
  "CPFL Energias Renováveis S.A.",
  "CHANAKYA DAIRY PRODUCTS LIMITED",
  "AÞKALE Elektrik Üretim A.Þ",
  "IRMAK Enerji. Üretim San. ve Tic. Ltd. Sti.",
  "Industrial Progressive India Limited",
  "Godrej Industries Limited",
  "Peabody Natural Gas, LLC",
  "Multiple Proponents",
  "Elite Elektrik Üretim ve Makine San. Tic. A.S.",
  "Guohua Resourceful (Rongcheng) Wind Power Generation Co., Ltd.",
  "Kwality Limited (formerly known as Kwality Dairy (",
  "Modern Dairies Limited",
  "Guohua Resourceful (Rongcheng) Wind Power Generation Co., Ltd.",
  "City of Honolulu, Department of Environmental Serv",
  "Local Energy Alliance Partnership (LEAP)",
  "FARMGATE AGRO MILCH PRIVATE LIMITED",
  "R del Sur, S.A.",
  "Evergreen Ecotech Private Limited",
  'Milton A. Salzedas Panorama - "ME”',
  "Guohua (Tongliao) Wind Power Co., Ltd.",
  "Govt. of Madagascar, Direction Generale des Forets",
  "Guohua (Tongliao) Wind Power Co.,Ltd.",
  "Guohua (Tongliao) Wind Power Co., Ltd.",
  "Guohua (Tongliao) Wind Power Co., Ltd.",
  "Godrej Agrovet Limited",
  "Godrej Agrovet Limited",
  "Multiple Proponents",
  "Livelihoods Fund SICAV SIF",
  "Hydelec Madagascar",
  "Bhoruka Power Corporation Limited",
  "Sichuan Ya an Qingyuan Hydro energy Co., Ltd.",
  "Alto Maipo SpA",
  "Ayen Enerji A.S.",
  "Cera Sanitaryware Limited",
  "Standard Bank Plc",
  "Multiple Proponents",
  "The Nature Conservancy",
  "EcoFormation",
  "Livelihoods Fund SICAV SIF",
  "Multiple Proponents",
  "MMTC Limited",
  "Masat Enerji Elektrik Uretim ve Tic. Ltd. Sti.",
  "KAM Enerji Uretim Sanayi ve Ticaret",
  "Multiple Proponents",
  "ALPEREN A.S.",
  "Yunnan Tengzhong New Energy Technology Co. Ltd.",
  "Kanaka Management Services Private Limited",
  "ETKEN Elektrik Enerji Uretim Ltd.Sti.",
  "Biltech Building Elemnts Limited",
  "Biltech Building Elemnts Limited",
  "Renencom",
  "CRP India Private Ltd",
  "Oromia Forest and Wildlife Enterprise",
  "Friends Salt Works & Allied Ind. & Gautam Freight",
  "Multiple Proponents",
  "Spekboom Trading (Pty) Ltd",
  "Murat HEPP Energy Electricity Generation and Trade",
  "Boyabat Elektrik Uretim Ve Ticaret AS",
  "FFC Energy Limited",
  "Viability Incentives",
  "Empresa Electrica Rio Doble",
  "Palmatir S.A.",
  "Guohua (Hebei) Renewable Energy Co Ltd.",
  "Korea East-West Power Co., Ltd",
  "Plant your Future",
  "TAMILNADU SPINNING MILLS ASSOCIATION (TASMA)",
  "TAMILNADU SPINNING MILLS ASSOCIATION (TASMA)",
  "BALL STATE UNIVERSITY",
  "Multiple Proponents",
  "Guohua (Jiangsu) Wind Power Co., Ltd.",
  "Eregli Demir ve Celik Fabrikalari T.A.S.",
  "Eregli Demir ve Celik Fabrikalari T.A.S.",
  "SAFBOIS, SPRL",
  "Enercon (India) Limited",
  "Multiple Proponents",
  "Multiple Proponents",
  "Shandan Xiehe Solar power generation Co., Ltd.",
  "Multiple Proponents",
  "Generadora Luzma S.A. E.S.P.",
  "Multiple Proponents",
  "Bansal WindMill Pvt. Ltd.",
  "EFM Investments & Advisory, Inc.",
  "Hassas Teknik Enerji Elektrik Uretim Sanayi ve Ticaret A.S.",
  "Scitus Luzhou Cement Co., Ltd",
  "Carbon Resource Management Ltd",
  "Guizhou Kai Li Rui An Jian Cai Co., Ltd",
  "Guizhou Kong On Cement Company Limited",
  "SAF ENERJİ ELEKTRİK ÜRETİM SAN.VE TİC. A.Ş.",
  "Valencia College",
  "Boston University",
  "Portland State University",
  "Spelman College",
  "Lock Haven City Authority",
  "Multiple Proponents",
  "Grand Valley State University",
  "Commonwealth New Bedford Energy LLC",
  "Aydem Yenilenebilir Enerji A.Ş.",
  "Multiple Proponents",
  "Multiple Proponents",
  "University of Wisconsin",
  "Consejo Nacional de Áreas Protegidas (CONAP)",
  "Albe Enerji Elektrik Elektronik Danismanlik",
  "RECAST ENERGY LOUISVILLE, LLC 2",
  "Satia Industries Limited",
  "Multiple Proponents",
  "Multiple Proponents",
  "Rithwik Power Projects Limited",
  "Multiple Proponents",
  "Multiple Proponents",
  "Consejo Comunitario de Cajambre",
  "Multiple Proponents",
  "EY-TUR ENERJI ELEKTRIK URETIM VE TIC. LTD. STI.",
  "Multiple Proponents",
  "Multiple Proponents",
  "Multiple Proponents",
  "Provincial Office of Agriculture and Forestry (PAFO)",
  "Cabildo Mayor Indígena de Mutatá",
  "Blue Source, LLC",
  "MGM Worldwide LLC",
  "Consejo Comunitario de Concosta",
  "Multiple Proponents",
  "Ati Energy and Electric Power Generation Company",
  "Swire Pacific Offshore Operations (Pte) Ltd. (SPO)",
  "ReNew Wind Energy (Shivpur) Private Limited",
  "AXIA Energia",
  "Gansu Deyou Energy Technology Co., Ltd",
  "University of Illinois Champaign-Urbana",
  "Chyulu Hills Conservation Trust",
  "AKKOY ENERJI A.S.",
  "ENERGISA S.A.",
  "Alex Astral Power Private Limited",
  "Multiple Proponents",
  "ENERSE Enerji Elektrik Ure. Ins. Mad. San. ve Tic.",
  "Samta Energy Private Limited",
  "Multiple Proponents",
  "Rochester Institute of Technology",
  "KARYEK KARADENIZ ELEKTRIK URETIM A.S.",
  "Servicios Comerciales de Energia S.A. de C.V.",
  "LNB Renewable Energy Private Limited",
  "Rajasthan Gum Private Limited",
  "Green Resources Tanzania Ltd (GRL)",
  "Generacion de Energia Renovable S.A.(GERSA)",
  "SNS Starch Limited",
  "Hira Steels Ltd.",
  "NHPC Ltd",
  "NHPC Ltd",
  "NHPC Ltd",
  "NHPC Ltd",
  "Peker Enerji A.S.",
  "Multiple Proponents",
  "Multiple Proponents",
  "Kanaka Management Services Pvt. Ltd",
  "DRRK Foods Private Limited",
  "Birla Century",
  "LinCang Yun Tou Yue Dian Hydropower Development Co.,Ltd",
  "Secretaría del Medio Ambiente del Municipio de Medellín",
  "Credits transferred from approved GHG program",
  "Multiple Proponents",
  "Egenda Ege Enerji Uretim A.S",
  "Egenda Ege Enerji Uretim A.S",
  "Egenda Ege Enerji Uretim A.S",
  "PT Indonesia Power",
  "Credits transferred from approved GHG program",
  "Vish Wind Infrastructure LLP",
  "Vish Wind Infrastructure LLP",
  "Horn of Africa Regional Environment Center and Network",
  "Three Gorges New Energy Jiuquan Co., Ltd",
  "Fujian Energy Wind Power Co., Ltd.",
  "Sichuan Furong Group's Limited Industrial Company",
  "Bothe Windfarm Development Pvt. Ltd.",
  "Fujian Energy Wind Power Co., Ltd.",
  "Credits transferred from approved GHG program",
  "Enercon (India) Limited",
  "Credits transferred from approved GHG program",
  "Credits transferred from approved GHG program",
  "Chanderpur Renewal Power CO. Pvt. Ltd.",
  "Gansu Heihe Hydropower New Energy Development Co., Ltd.",
  "Regional Government of Cajamarca",
  "Multiple Proponents",
  "ETKEN Elektrik Uretim Ltd.Şti.",
  "Bolu Çimento Sanayii A.Ş.",
  "Tany Meva Fundation",
  "Uhud Enerji Uretim Tic. ve San. A.S.",
  "Multiple Proponents",
  "DOGUS ENERJI URETIM VE TICARET A.S.",
  "China Three Gorges Renewables (Group) Co., Ltd.",
  "Livelihoods Fund SICAV SIF",
  "Limak Hidroelektrik Santral Yatırımları Anonim Sirketi",
  "Multiple Proponents",
  "Shah Promoters and Developers",
  "Northern Rangelands Trust",
  "ReNew Wind Energy (Rajasthan) Private Limited",
  "Multiple Proponents",
  "ReNew Wind Energy (Varekarwadi) Private Limited",
  "Re_New Wind Energy Delhi Private Limited",
  "ReNew Wind Energy (AP) Private Limited",
  "Energía del Suroeste S.A E.S.P",
  "Credits transferred from approved GHG program",
  "PT. Rimba Makmur Utama (PT. RMU)",
  "Multiple Proponents",
  "CLP Wind Farms (India) Private Limited",
  "Hebei Construction Investment Zhangjiakou Wind Energy Co, Ltd.",
  "CLP Wind Farms (India) Private Limited",
  "CLP Wind Farms (India) Private Limited",
  "CLP Wind Farms (India) Private Limited",
  "Sarako Pvp Co Ltd",
  "Kalima Hydro Power Private Limited",
  "Achar Energy 2007 Ltd. Co.",
  "Infinite Solutions",
  "Credits transferred from approved GHG program",
  "Shandong Luneng Development Group Co., Ltd.",
  "Huaneng Nanjing Jinling Power Generation Co., Ltd",
  "Multiple Proponents",
  "Astidey S.A",
  "Livelihoods Fund SICAV SIF",
  "HENRI FRAISE FILS & Cie",
  "Selco Solar Pvt. Ltd.",
  "Pur Projet",
  "Multiple Proponents",
  "KPHP Tasik Besar Serkap (KPHP TBS), Riau Province-Indonesia",
  "Aydem Yenilenebilir Enerji A.Ş.",
  "Blue Source, LLC",
  "Chifeng Xinsheng Wind Power Co., Ltd.",
  "Reis Rs Enerji Elektrik Üretim Sanayi ve Ticaret A.Ş.",
  "Fundacio Catalunya-La Pedrera",
  "MTP HPPO Manufacturing Co.",
  "Multiple Proponents",
  "Credits transferred from approved GHG program",
  "Credits transferred from approved GHG program",
  "Aydem Yenilenebilir Enerji A.Ş.",
  "Credits transferred from approved GHG program",
  "Credits transferred from approved GHG program",
  "Kıyı Enerji Elektrik Uretim Anonim Sirketi",
  "Zhejiang Guohua Yuyao Fuel Gas Power Generation Co",
  "Credits transferred from approved GHG program",
  "Credits transferred from approved GHG program",
  "Credits transferred from approved GHG program",
  "Credits transferred from approved GHG program",
  "Credits transferred from approved GHG program",
  "Credits transferred from approved GHG program",
  "Credits transferred from approved GHG program",
  "Zaveri & Company Gujarat LLP",
  "NSL Wind Power Company (Satara) Pvt. Ltd.",
  "Ningxia Tianjing Wind Power Generation Electricity",
  "M/s NSL Wind Power Company (Kayathar) Pvt. Ltd.",
  "Mytrah Energy (INDIA) Ltd.",
  "CI-ENERGIES",
  "Panama Wind Energy Godawari Private Limited",
  "AXIA Energia",
  "Serum Institute of India Limited",
  "Evergreen Ecotech Private Limited",
  "Credits transferred from approved GHG program",
  "Credits transferred from approved GHG program",
  "Chao’er Forest Bureau of Inner Mongolia Autonomous Region",
  "Nahar Industrial Enterprises Limited",
  "Multiple Proponents",
  "Community Markets for Conservation (COMACO)",
  "Green Dream Farm",
  "Rajasthan Gum Private Limited",
  "Everest Starch India Private Limited",
  "Roha Dyechem Private Limited",
  "Credits transferred from approved GHG program",
  "Negocios Energeticos De Occidente, S.A.",
  "Gangakhed Sugar & Energy Private Limited (GSEPL)",
  "Xiamen HSE Environmental Engineering Consulting",
  "Multiple Proponents",
  "Kunming Yuming Investment Development Co., Ltd.",
  "Agroempresa Forestal S.A.",
  "JSC Terneyles",
  "One True Measure (Pty) Ltd",
  "Aydem Yenilenebilir Enerji A.Ş.",
  "Multiple Proponents",
  "Aquila Technologies, Inc",
  "Aerocon Buildwell Pvt Ltd",
  "Carbon-TF B.V.",
  "Multiple Proponents",
  "Rajasthan Gum Private Limited",
  "Multiple Proponents",
  "ERB MG Energias S/A",
  "Colbun S.A.",
  "Energias Limpias del Yaguala S.A. de C.V.",
  "Livelihoods Fund SICAV SIF",
  "M/s. Master Wind Energy Limited",
  "Cixi Yangtze River Wind Power Co., Ltd.",
  "CLP Wind Farms (India) Private Limited",
  "Global Servicos de Engenharia",
  "Aures Bajo S.A E.S.P",
  "Adani Enterprises Limited",
  "Credits transferred from approved GHG program",
  "Multiple Proponents",
  "Lalpur Wind Energy Private Limited",
  "Multiple Proponents",
  "Yalova Rüzgar Enerjisinden Elektrik Üretim Anonim Sirketi",
  "Zhejiang Meida Electric Power Development Co., Ltd",
  "Multiple Proponents",
  "Multiple Proponents",
  "R DEL ESTE, S.A.",
  "Multiple Proponents",
  "Yong’an Forestry Bureau of Fujian province, P.R.China",
  "ACME Solar Technologies (Gujarat) Private Limited",
  "LNB Renewable Energy Private Limited",
  "Multiple Proponents",
  "Multiple Proponents",
  "Hero Future Energies Private Limited",
  "Clean Solar Power (Dhar) Private Limited",
  "Rajasthan State Mines & Minerals Ltd",
  "Derne Temiz Enerji A.S.",
  "Carbon-TF B.V.",
  "Fundação Amazonas Sustentável - FAS",
  "Blue Source, LLC",
  "Carbon-TF B.V.",
  "Global Climate Strategies",
  "Carbon-TF B.V.",
  "Fundacion para el Ecodesarrollo y la Conservacion [FUNDAECO]",
  "Yesilvadi Elektrik Üretim A.S.",
  "CG Brewery (Nepal) Pvt. Ltd",
  "Carbon-TF B.V.",
  "EcoSecurities International Limited",
  "New Horizons Waste to Energy (Pty) Ltd",
  "EMPRESA GENERADORA DE ELECTRICIDAD HAINA S.A. (EGE HAINA)",
  "Royal Government of Cambodia (RGC), Ministry of Environment",
  "JK Lakshmi Cement Ltd.",
  "SENELEC",
  "Fazenda Nossa Senhora das Cachoeiras do Ituxi",
  "Credits transferred from approved GHG program",
  "Fundación Natura",
  "Credits transferred from approved GHG program",
  "Rhenus Recycling GmbH",
  "Yunnan New View Power Co.,Ltd.",
  "M/s Sispara Renewable Power Private Limited",
  "Alisios Holdings, S.A.",
  "ClimateCare Limited",
  "CARBON CREDITS CONSULTING S.R.L.",
  "Xishuangbanna Forestry Bureau of Yunnan province, P.R.China.",
  "Liberian Agricultural Company",
  "Multiple Proponents",
  "ZT Enerji Elektrik Üretim San. Ve Tic. A.S.",
  "Giriraj Enterprises",
  "CoolClimate Holding, Inc. dba AtmosClear",
  "Multiple Proponents",
  "Panama Wind Energy Private Limited",
  "Zambruss Enterprises",
  "Hyderabad Industries Limited",
  "National Mozambican Public Agency for Parks and Reserves Conservation (ANAC)",
  "University of Wisconsin Milwaukee (UWM)",
  "Kurtal Elektrik Uretim A.S.",
  "Credits transferred from approved GHG program",
  "Credits transferred from approved GHG program",
  "D. J Malpani",
  "Multiple Proponents",
  "3Degrees Group, Inc",
  "3Degrees Group, Inc",
  "The PURE PROJECT SAS",
  "ENERGY RESOURCES SENEGAL SA",
  "Lao Thai Hua Rubber Co. Ltd",
  "GMR Gujarat Solar Power Pvt. Ltd",
  "Agrocortex Madeiras do Acre Agroflorestal Ltda.",
  "Multiple Proponents",
  "The Royal Government of Cambodia, Forestry Administration",
  "Pailou Hydropower of Zhongfang County Co., Ltd.",
  "Credits transferred from approved GHG program",
  "M/s Astha Projects (India) Limited",
  "RSPL Limited",
  "Baidyanath Power Private Limited",
  "Local Environmental Authority of Chivor (CORPOCHIVOR)",
  "Credits transferred from approved GHG program",
  "Credits transferred from approved GHG program",
  "Credits transferred from approved GHG program",
  "Credits transferred from approved GHG program",
  "Blue Source, LLC",
  "Rajasthan State Mines & Minerals Ltd",
  "Multiple Proponents",
  "Credits transferred from approved GHG program",
  "Credits transferred from approved GHG program",
  "Credits transferred from approved GHG program",
  "Godrej Properties Ltd",
  "Jindal Poly Films Limited",
  "UDP CONSORCIO AGM DESARROLLOS",
  "Multiple Proponents",
  "Pingwu Chuanjiang Hydropower Development Co., Ltd",
  "Godrej Consumer Products Limited",
  "Multiple Proponents",
  "Multiple Proponents",
  "AERA Group",
  "Multiple Proponents",
  "Multiple Proponents",
  "Fugong Hongda Hydropower Development Co. Ltd",
  "Multiple Proponents",
  "Multiple Proponents",
  "HIDROELECTRICA DEL ALTO PORCE",
  "Multiple Proponents",
  "Multiple Proponents",
  "Jaiprakash Power Ventures Limited",
  "JK Lakshmi Cement Ltd.",
  "Credits transferred from approved GHG program",
  "Esinti Enerji Uretim Ticaret ve Sanayi Anonim Sirketi.",
  "AERA Group",
  "AERA Group",
  "Negros Island Solar Power Inc.",
  "Pacificlight Power Pte. Ltd.",
  "Credits transferred from approved GHG program",
  "Darjeeling Power Pvt. Ltd",
  "Panzhihua Reshuihe Electric Power Development Co.",
  "Agropecuaria Santa Genoveva S.A.P.I. de C.V.",
  "YUMRUTEPE ENERJI URETIM ANONIM SIRKETI",
  "JSW Hydro Energy Limited",
  "BNC REDD+",
  "Godrej Agrovet Limited",
  "Multiple Proponents",
  "Multiple Proponents",
  "Royal Government of Cambodia (RGC), Ministry of Environment",
  "Panzhihua Reshuihe Electric Power Development Co.",
  "Credits transferred from approved GHG program",
  "Credits transferred from approved GHG program",
  "Tradewater, LLC",
  "Multiple Proponents",
  "Southern Power Company",
  "Karadeniz Hes Elektrik Anonim Şirketi",
  "Everest Starch (IND) Pvt. Ltd.",
  "Carbon-TF B.V.",
  "Multiple Proponents",
  "SolarArise India Projects Pvt. Ltd.",
  "Worldview International Foundation",
  "Corporación Autónoma Regional del Río Grande de la Magdalena (CORMAGDALENA)",
  "AERA Group",
  "Multiple Proponents",
  "Kamuthi Solar Power Limited",
  "Multiple Proponents",
  "Carbon-TF B.V.",
  "Multiple Proponents",
  "Vena Energy KN Wind Power Private Limited",
  "NTPC LIMITED",
  "Senergy 2 SAS",
  "Multiple Proponents",
  "Green Infra Wind Power Generation Limited",
  "Multiple Proponents",
  "Carbon-TF B.V.",
  "Modern Road Makers Pvt Ltd.",
  "Prayatna Developers Private Limited",
  "Ramnad Renewable Energy Ltd",
  "Mytrah Energy India Private Limited",
  "Giriraj Renewables Private Limited",
  "Animala Wind Power Private Limited",
  "Saipuram Wind Energies Private Limited",
  "SEI Kathiravan Power Private Limited",
  "Sajjan India Ltd.",
  "Axis Wind Farms (MPR Dam) Private Limited",
  "SEI Suryashakti Power Private Limited",
  "Zuvan Energy Private Limited",
  "Devarahipparigi Wind Power Private Limited",
  "Interwaste (Pty) Ltd",
  "Desarrollo de Energías Renovables S.A.S .ES.P.",
  "Multiple Proponents",
  "PCH San Bartolomé S.A.S .ES.P.",
  "Multiple Proponents",
  "Agcert do Brasil Solucoes Ambientais Ltda.",
  "Rialma Companhia Energética I S.A.",
  "DLI Power (India) Private Limited",
  "Southern Power Company",
  "Multiple Proponents",
  "Combio Energia S.A.",
  "Adani Renewable Energy Devco Private Limited",
  "Consejo Comunitario General Los Riscales.",
  "Dynamic Fine Paper Mill Pvt. Ltd.",
  "Southern Power Company",
  "GERES (Gpe Energies Renouvelables & Environnement)",
  "Southern Power Company",
  "Multiple Proponents",
  "Multiple Proponents",
  "Vish Wind Infrastructure LLP",
  "Adani Green Energy Limited",
  "Vaayu Renewable Energy (Tapti) Private Limited",
  "Recarbon Ground Trading (Pty) Ltd",
  "Hindustan Zinc Limited",
  "Carbon-TF B.V.",
  "YPF Energia Electrica S.A.",
  "Ciprogress Greenlife",
  "POWERICA LIMITED",
  "POWERICA LIMITED",
  "Liuzhou City Xin’neng Biomass Power Generation Co., Ltd.",
  "Qinghai Forestry Ecological Construction and Investment Co., Ltd.",
  "Qinghai Forestry Ecological Construction and Investment Co., Ltd.",
  "Yulin Yihong New Energy Co., Ltd.",
  "Malwa Power Private Limited",
  "Dee Development Engineers Pvt. Ltd.",
  "Carbon-TF B.V.",
  "POWERICA LIMITED",
  "POWERICA LIMITED",
  "Qinghai Forestry Ecological Construction and Investment Co., Ltd.",
  "WeAct Pty Ltd",
  "Caruquia S.A.S .ES.P.",
  "Guanaquitas S.A.S .ES.P.",
  "Vish Wind Infrastructure LLP",
  "CECIC HKE Wind Power CO.,LTD",
  "Carbon-TF B.V.",
  "Colbún S.A.",
  "INLAND ENERGY",
  "Clean Sustainable Energy Pvt. Ltd.",
  "Giriraj Renewables Private Limited",
  "Fermi Solar Farms Pvt. Ltd.",
  "Avaada Sustainable Energy Pvt. Ltd.",
  "Solarsys Non conventional Energy Pvt. Ltd.",
  "Guizhou Baiheng Fertiliser Co., Ltd",
  "Multiple Proponents",
  "Ranchland Solar, LLC",
  "Arcor Group",
  "Credits transferred from approved GHG program",
  "ReNew Solar Power Private Limited",
  "Multiple Proponents",
  "KPL International Limited",
  "Shri Keshav Cements & Infra Ltd.",
  "Hefei Zhuoya Landscaping Engineering Co., Ltd.",
  "Green Infra Wind Energy Limited",
  "Multiple Proponents",
  "Clean Air Trade, Inc.",
  "Shaanxi Lan River Hydropower Development Co.,Ltd",
  "Clean Air Trade, Inc.",
  "Winsome Textile Industries Limited",
  "Multiple Proponents",
  "Enen Green Services Private Limited",
  "Guizhou Baiheng Fertiliser Co., Ltd",
  "Guizhou Baiheng Fertiliser Co., Ltd",
  "Makit Base Junlian Cotton Industry Co., Ltd.",
  "Ningxia Zhongwei Aluminum New Energy Co., Ltd",
  "GELEC GUACHICONO S.A.S .E.S.P",
  "Société de gestion de projets ECOTIERRA Inc.",
  "Shaanxi Hanjiang Hydropower Development Co., Ltd",
  "Solarges Elektrik Üretim A.Ş",
  "Multiple Proponents",
  "Multiple Proponents",
  "Credits transferred from approved GHG program",
  "Guangxi Fusui Qiquan biomass power generation Co., Ltd.",
  "Kare Power Resources Private Limited. (KPRPL)",
  "Multiple Proponents",
  "Credits transferred from approved GHG program",
  "Granja Tres Arroyos S.A.",
  "Panamanian Pro Carbon Association (Asociación Panameña Pro Carbono. Approcarbono)",
  "Asociacion Amazonicos por la Amazonía",
  "EMAC-BGP ENERGY COMPANÍA DE ECONOMÍA MIXTA CEM",
  "ZSCORE S. A.",
  "AERA Group",
  "Credits transferred from approved GHG program",
  "Credits transferred from approved GHG program",
  "3Degrees Group, Inc",
  "CLP India Private Limited",
  "NHPC Ltd",
  "Multiple Proponents",
  "International Power Corporation Limited",
  "Jilin Forest Industry Environmental Technology Co., Ltd.",
  "Jilin Forest Industry Environmental Technology Co., Ltd.",
  "Carbon Tanzania",
  "Clean Energy Asia LLC",
  "Multiple Proponents",
  "Agcert do Brasil Solucoes Ambientais Ltda.",
  "Carbon Tanzania",
  "CECIC Wind Power (Zhangbei) Yunwei Co., Ltd",
  "Credits transferred from approved GHG program",
  "Multiple Proponents",
  "Green Infra Renewable Energy Limited",
  "Isider Enerji Uretim Pazarlama Ithalat ve Ihracat A.S.",
  "Kovanci Enerji Uretim Pazarlama Ithalat ve Ihracat A.S.",
  "Derbent Enerji Uretim Pazarlama Ithalat ve Ihracat A.S.",
  "Korda Enerji Uretim Pazarlama Ithalat ve Ihracat A.S.",
  "Credits transferred from approved GHG program",
  "Dehong Kairui Dayingjiang Hydropower Dev. Co. Ltd.",
  "Credits transferred from approved GHG program",
  "King County Department of Natural Resources, Water and Land Division",
  "Alaknanda Hydro Power Company Limited",
  "Galata Wind Enerji A.S.",
  "Avaada Solar Energy Private Limited",
  "AERA Group",
  "Multiple Proponents",
  "Credits transferred from approved GHG program",
  "Blue Source, LLC",
  "Adeco Agropecuaria S.A.",
  "Yunnan Baoshan Binglang River Hydropower Development Co., Ltd.",
  "Satia Industries Limited",
  "Omicron-PSI Enerji A.S.",
  "Neptun Enerji A.S.",
  "YAYSUN-MT Enerji A.S.",
  "Bahceli Enerji A.S.",
  "ME-SE Enerji A.S.",
  "IOTA Gunes Enerjisi Elektrik Uretim ve Ticaret A.S.",
  "Electrify America",
  "Yunnan Baoshan Supahe Hydropower Development Co.,",
  "Beijing Pingtouge Forestry Carbon Technology Development Co.,Ltd",
  "Azure Power India Pvt ltd",
  "Mavibayrak Enerji Uretim A.S.",
  "Patikari Power Private Limited",
  "Zhejiang Zhongzheng Forestry Development Co.,Ltd",
  "Multiple Proponents",
  "Multiple Proponents",
  "CECEP Wind Power (Gansu) Co., Ltd",
  "Yunnan Baoshan Supahe Hydropower Development Co.,",
  "CECEP Wind Power (Gansu) Co., Ltd",
  "Indore Smart City Development Ltd",
  "Hero Future Energies Private Limited",
  "Orissa Power Consortium Ltd.",
  "Livelihoods Fund SICAV SIF",
  "Araucania Power S.A.",
  "Hero Future Energies Private Limited",
  "Inner Mongolia Xinjin Wind Power Co., Ltd.",
  "Multiple Proponents",
  "Parakh Agro Industries Ltd.",
  "Multiple Proponents",
  "Huaneng Changtu Wind Power Co., Ltd.",
  "Huaneng Jilin Power Generation Co., Ltd.",
  "Floresta Verde Serviços Ltda.",
  "Eni S.p.A.",
  "Tonghua Xinneng Biomass Power Cogeneration Co., Ltd.",
  "Shaanxi Chengcheng Qinyao Power Company",
  "My Son Hoan Loc Viet Solar Energy Joint Stock Company",
  "AERA Group",
  "Tiefa Coal Industry (Group) Co., Ltd.",
  "Native, a Public Benefit Corporation",
  "Shalivahana Green Energy Limited",
  "Credits transferred from approved GHG program",
  "SEREE",
  "Srepok Solar Power Joint Stock Company",
  "Binh Dinh TTP Energy and High Technology Joint Stock Company",
  "Huaneng Huajialing Wind Power Co., Ltd.",
  "Shalivahana Green Energy Limited",
  "CLP India Pvt Limited",
  "Amazon Reforestation Consortium.",
  "Multiple Proponents",
  "Ventus, S.A.de C.V",
  "AERA Group",
  "Guangxi Deli New energy Technology Co. ltd",
  "Huaneng Jilin Power Co., Ltd.",
  "Dai Hai Power Development and Invesment Joint Stock Company",
  "Ha Do Binh Thuan Co., Ltd",
  "Multiple Proponents",
  "Multiple Proponents",
  "Shalivahana Green Energy Limited",
  "Multiple Proponents",
  "Bhoruka Power Corporation Limited",
  "JK Cement Ltd. (JKCL)",
  "Ngodwana Energy (Pty) Ltd.",
  "Credits transferred from approved GHG program",
  "Multiple Proponents",
  "Multiple Proponents",
  "Sagar Power Dandela Private Limited",
  "GENNEIA S.A.",
  "Newlight Technologies",
  "AERA Group",
  "Multiple Proponents",
  "Multiple Proponents",
  "Hindustan Zinc Limited",
  "Hindustan Zinc Limited",
  "Multiple Proponents",
  "PEBSA",
  "Multiple Proponents",
  "GENNEIA S.A.",
  "Tropical Wind Co., Ltd",
  "CERRO DOMINADOR CSP S.A.",
  "Krissana Wind Power Co., Ltd",
  "Lanco Infratech Limited",
  "Agcert do Brasil Solucoes Ambientais Ltda.",
  "Multiple Proponents",
  "KRS Three Co., Ltd",
  "KR One Co., Ltd",
  "Theparak Wind Co., Ltd",
  "Alcom Energy",
  "Numerco Limited",
  "Numerco Limited",
  "First Korat Wind Company Limited",
  "Acwa Ben Ban one for Energy",
  "TK for Solar and Renewable Energy Systems",
  "Enen Green Services Private Limited",
  "Multiple Proponents",
  "Zhejiang Zhongtuo Environmental Technology Development Co.,Ltd",
  "Huaneng New Energy Industrial Co., Ltd",
  "Huaneng New Energy Industrial Co., Ltd",
  "Huaneng New Energy Industrial Co., Ltd",
  "Avaada Non-Conventional UPProject Private Limited",
  "ACME Cleantech Solutions Private Limited",
  "South Pole Carbon Asset Management Ltd",
  "Avaada Solarise Energy Private Limited",
  "Alianca Geracao de Energia S.A.",
  "Verdant Renewables Private Limited",
  "Multiple Proponents",
  "M/s Mytrah Vayu (Sabarmati) Pvt. Ltd",
  "Alianca Geracao de Energia S.A.",
  "Daqing Lvyuan Wind Power Co., Ltd.",
  "Rishabh Renergy Private Limited",
  "Nam Si Luong 1 Hydropower Joint Stock Company",
  "Huaneng Tulufan Wind Power Generation Company Ltd",
  "AD Hydro Power Limited",
  "Hindustan Zinc Limited",
  "Avaada Non-Conventional UPProject Private Limited",
  "Daqing Lvyuan Wind Power Co., Ltd.",
  "Carbon-TF B.V.",
  "Daqing Lvyuan Wind Power Co., Ltd.",
  "ReNew Power Limited",
  "Avaada Sunrise Energy Private Limited",
  "Multiple Proponents",
  "Shams Ad-Dhahira Generating Company SAOC",
  "ReNew Solar Power Private Limited",
  "Numerco Limited",
  "Multiple Proponents",
  "Azure Power India Pvt ltd",
  "Eni S.p.A.",
  "Carbon-TF B.V.",
  "Vinh Hao 6 Power Joint Stock Company",
  "South Pole Carbon Asset Management Ltd",
  "Adani Green Energy Limited",
  "Adani Green Energy Limited",
  "Torrent Power Limited",
  "Huaneng Tulufan Wind Power Generation Company Ltd",
  "ACWA Power Komombo for Energy",
  "Shalivahana Green Energy Limited",
  "Huaneng Shilin Photovolt Energy Development Co.,Ltd.",
  "Daqing Lvyuan Wind Power Co., Ltd.",
  "Carbon-TF B.V.",
  "Huaneng Power International, Inc.",
  "South Pole Carbon Asset Management Ltd",
  "Axis Wind Farms (Rayalaseema) Pvt. Ltd",
  "SMART HOLDING A.S.",
  "JSW Energy Kutehr Ltd.",
  "Guangdong Shaoneng Group Co.,Ltd.",
  "Hindustan Zinc Limited",
  "SolarArise India Projects Pvt. Ltd.",
  "Jakson Power Pvt. Ltd",
  "Mahindra Renewables Private Limited.",
  "Carbon-TF B.V.",
  "Empresa de Acueducto y Alcantarillado de Bogota E.S.P",
  "E G Wind S.A.",
  "K.R. Two Company Limited",
  "Luz de Tres Picos",
  "Torrent Power Limited",
  "Multiple Proponents",
  "PEBSA",
  "Rao Trang Hydropower Joint Stock Company",
  "Azure Power Forty three Pvt. Ltd.",
  "Fortum India Private Limited",
  "Carbon-TF B.V.",
  "Guizhou Baiheng Fertiliser Co., Ltd",
  "Multiple Proponents",
  "MOOTRAL SA",
  "Connecticut Green Bank",
  "Godrej Agrovet Limited",
  "Credits transferred from approved GHG program",
  "Asociación Tu'ik Ruch Lew",
  "CARBON CREDITS CONSULTING S.R.L.",
  "Carbon-TF B.V.",
  "BETC Nanala",
  "Guizhou Xinzhanxin Agricultural Technology Co., Ltd.",
  "Guizhou Xinzhanxin Agricultural Technology Co., Ltd.",
  "ALLCOT AG",
  "Agrimoz S.a r.l.",
  "Xichuan Rongda Agriculture and Forestry Co., Ltd",
  "Worldview International Foundation",
  "Credits transferred from approved GHG program",
  "Carbon-TF B.V.",
  "Credits transferred from approved GHG program",
  "DNZ ELEKTRİK ÜRETIM A.Ş.",
  "Baren Enerji Sanayi ve Ticaret Üretim A.Ş.",
  "MAHINDRA WASTE TO ENERGY SOLUTIONS LTD.",
  "'SANKO Enerji Sanayi ve Ticaret A.Ş",
  "Aydem Yenilenebilir Enerji A.Ş.",
  "Arı Su Enerji Üretim A.Ş.",
  "'SANKO Enerji Sanayi ve Ticaret A.Ş",
  "Foshan Nanhai Green Renewable Energy Co., Ltd.",
  "Ningbo Micropowers New Energy Co., Ltd.",
  "Agcert do Brasil Solucoes Ambientais Ltda.",
  "Carbon-TF B.V.",
  "Carbon-TF B.V.",
  "Carbon-TF B.V.",
  "CDC",
  "Oswal Cables Private Limted",
  "Carbon-TF B.V.",
  "MER",
  "Carbon-TF B.V.",
  "Carbon-TF B.V.",
  "MER",
  "Carbon-TF B.V.",
  "Multiple Proponents",
  "Multiple Proponents",
  "Carbon-TF B.V.",
  "MER",
  "Carbon-TF B.V.",
  "Carbon-TF B.V.",
  "Agcert do Brasil Solucoes Ambientais Ltda.",
  "Carbon-TF B.V.",
  "Carbon-TF B.V.",
  "Carbon-TF B.V.",
  "Carbon-TF B.V.",
  "Multiple Proponents",
  "Multiple Proponents",
  "M/s Nagreeka Foils Limited",
  "Southern Power Company",
  "Wind Urja India Private Limited",
  "Ratedi Wind Power Private Limited",
  "Tadas Wind Energy Private Limited",
  "Ratedi Wind Power Private Limited",
  "Lalpur Wind Energy Private Limited",
  "Lalpur Wind Energy Private Limited",
  "Lalpur Wind Energy Private Limited",
  "Beijing Qianyuhui Environmental Technology Co., Ltd.",
  "Multiple Proponents",
  "Multiple Proponents",
  "Multiple Proponents",
  "Brazil AgFor LLC",
  "Multiple Proponents",
  "3Degrees Group, Inc",
  "Carbon-TF B.V.",
  "Shandong Micropowers New Energy Co., Ltd.",
  "Chokyuenyong Industry Co. Ltd.",
  "CUESTA Y ASOCIADOS SAS",
  "Multiple Proponents",
  "Carbon-TF B.V.",
  "Multiple Proponents",
  "Carbon-TF B.V.",
  "Carbon-TF B.V.",
  "Conservation International Foundation",
  "Guizhou Panjiang CBM Development&Utilization Co., Ltd.",
  "Guizhou Panjiang CBM Development&Utilization Co., Ltd.",
  "NIHT Inc.",
  "eCOGENT.biz",
  "CYY Global Plus Co., Ltd.",
  "Multiple Proponents",
  "Multiple Proponents",
  "Xiangtan Xiangneng New Energy Technology Co., Ltd.",
  "Agcert do Brasil Solucoes Ambientais Ltda.",
  "Carbon-TF B.V.",
  "Multiple Proponents",
  "Zhangjiakou Sailin Landscaping Co., Ltd.",
  "Liupanshui Zhongding New Energy Development Co., Ltd.",
  "Environment Protection Centre (EPC)",
  "Multiple Proponents",
  "Zhangjiakou Sailin Landscaping Co., Ltd.",
  "Converted from other GHG program",
  "GreenYellow Madagascar",
  "Voltas Yellow Ltd",
  "Akuo Energy (Mauritius) Ltd",
  "Carbon-TF B.V.",
  "Guizhou Xinzhanxin Agricultural Technology Co., Ltd.",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Converted From a another GHG program CDM",
  "Multiple Proponents",
  "AERA Group",
  "FRM Commitment",
  "Carbon-TF B.V.",
  "Kanaka Management Services Pvt. Ltd",
  "Solifor Bloc Monet S.E.C.",
  "M/s Raajratna Energy Holdings Pvt Ltd.",
  "BioGuinea Foundation",
  "Jinxiu Yao Autonomous County Zhengtong Asset Management Co., Ltd",
  "Huanjiang Maonan Autonomous County Huashan Forestry Investment Co., Ltd",
  "Carbon-TF B.V.",
  "WeAct Pty Ltd",
  "Converted From a another GHG program CDM",
  "Converted From a another GHG program CDM",
  "Converted From a another GHG program CDM",
  "Zhangjiakou Xinzhongshui Energy Technology Ltd.",
  "Enen Green Services Private Limited",
  "Hainan Sanya Xinzhongshui Enviromental Protection Technology Co., Ltd.",
  "Clean Air Action Corporation",
  "Clean Air Action Corporation",
  "Shah Promoters and Developers",
  "Multiple Proponents",
  "C-Quest Capital SGS Stoves Private Limited",
  "Multiple Proponents",
  "Third Institute of Oceanography, Ministry of Natural Resources",
  "Green Gold Forestry Peru SA",
  "Cropcity Agrovet Pvt. Ltd",
  "Multiple Proponents",
  "Motion Energy Group",
  "C-Quest Capital SGS Stoves Private Limited",
  "Korea Energy Management Corporation",
  "Multiple Proponents",
  "C-Quest Capital SGS Stoves Private Limited",
  "Quanzhou BCCY New Energy CO., Ltd.",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "Yichang BCCY New Power Co., Ltd.",
  "Suzhou Baichang Renewable Energy Co., Ltd.",
  "WeAct Pty Ltd",
  "Multiple Proponents",
  "Nanchang Zhonglan Huanneng Technical Service Co. Ltd.",
  "The Green Branch",
  "M/s. Rajmal Lakhichand",
  "The Nature Conservancy",
  "Forestal San Pedro S.A.",
  "Hefei Luyu Agricultural Technology Co., Ltd",
  "Swiss carbon Value Ltd.",
  "MLR Forestal de Nicaragua S.A.",
  "Multiple Proponents",
  "Burapha Agroforestry Co. Ltd",
  "Forestal Apepu",
  "M/s. NITCO Limited",
  "Zhangye Academy of Forestry Sciences",
  "Multiple Proponents",
  "Multiple Proponents",
  "Future Carbon International LLC",
  "Jilin Forest Industry Environmental Technology Co., Ltd.",
  "Jilin Forest Industry Environmental Technology Co., Ltd.",
  "Jilin Forest Industry Environmental Technology Co., Ltd.",
  "Jilin Forest Industry Environmental Technology Co., Ltd.",
  "Guizhou Xinzhanxin Agricultural Technology Co., Ltd.",
  "Guizhou Xinzhanxin Agricultural Technology Co., Ltd.",
  "PT. Cipta Daya Nusantara",
  "C-Quest Capital Stoves Asia Limited",
  "Converted From a another GHG program CDM",
  "Achats Service International",
  "Guizhou Shuikuang Orion Clean Energy Co., Ltd.",
  "Wonderbag UK",
  "Cree First Nation of Waswanipi.",
  "Global Forest Support GmbH",
  "Guangxi Baixin Agricultural Technology Co., Ltd.",
  "Converted From a another GHG program CDM",
  "Credits transferred from approved GHG program",
  "Carbon Resource Management Ltd",
  "C-Quest Capital Stoves Asia Limited",
  "Tianshui Forestry Science Institute",
  "Empresa de Acueducto y Alcantarillado de Pereira S.A.S E.S. P",
  "Converted from other GHG program",
  "Converted From a another GHG program CDM",
  "Japan Asia Group Ltd",
  "Hefei Luyu Agricultural Technology Co., Ltd",
  "Hefei Luyu Agricultural Technology Co., Ltd",
  "Hefei Luyu Agricultural Technology Co., Ltd",
  "Hefei Luyu Agricultural Technology Co., Ltd",
  "Agcert do Brasil Solucoes Ambientais Ltda.",
  "M/s. Sri Kumarswamy Mineral Exports",
  "Converted From a another GHG program CDM",
  "Miro Forestry Developments Limited",
  "VPOWER New Energy Technology (LinYi) LTD",
  "Multiple Proponents",
  "Mangalam Timber Products Limited",
  "Dingxi City Chankou Forestry Proving Ground",
  "ALLCOT A.G.",
  "ALLCOT A.G.",
  "Sichuan Jiajinshan Forestry Bureau",
  "C-Quest Capital SGT Asia Stoves Private Limited",
  "Yanbian Heli Electric Power Co., Ltd.",
  "Miro Forestry Developments Limited",
  "AGRO EMPRESA FORESTAL SA",
  "Multiple Proponents",
  "Zhangjiakou Sailin Landscaping Co., Ltd.",
  "Element Markets Emissions, LLC",
  "G K Energy Marketers Pvt. Ltd",
  "G K Energy Marketers Pvt. Ltd",
  "G K Energy Marketers Pvt. Ltd",
  "Lanzhou Landscaping And Greening Service Center",
  "Zhangjiakou Sailin Landscaping Co., Ltd.",
  "Miyi Deshi Xinxin Hydroelectricity Co., Ltd.",
  "Zhangjiakou Sailin Landscaping Co., Ltd.",
  "G K Energy Marketers Pvt. Ltd",
  "G K Energy Marketers Pvt. Ltd",
  "G K Energy Marketers Pvt. Ltd",
  "G K Energy Marketers Pvt. Ltd",
  "G K Energy Marketers Pvt. Ltd",
  "G K Energy Marketers Pvt. Ltd",
  "G K Energy Marketers Pvt. Ltd",
  "G K Energy Marketers Pvt. Ltd",
  "G K Energy Marketers Pvt. Ltd",
  "PT Dalle Energy Batam",
  "G K Energy Marketers Pvt. Ltd",
  "Multiple Proponents",
  "Converted from other GHG program",
  "Converted from other GHG program",
  "Essakane Solar S.A.S.",
  "G K Energy Marketers Pvt. Ltd",
  "G K Energy Marketers Pvt. Ltd",
  "G K Energy Marketers Pvt. Ltd",
  "G K Energy Marketers Pvt. Ltd",
  "G K Energy Marketers Pvt. Ltd",
  "Kashyap J. Majethia",
  "G K Energy Marketers Pvt. Ltd",
  "G K Energy Marketers Pvt. Ltd",
  "G K Energy Marketers Pvt. Ltd",
  "G K Energy Marketers Pvt. Ltd",
  "G K Energy Marketers Pvt. Ltd",
  "G K Energy Marketers Pvt. Ltd",
  "G K Energy Marketers Pvt. Ltd",
  "G K Energy Marketers Pvt. Ltd",
  "G K Energy Marketers Pvt. Ltd",
  "Tradewater International SRL",
  "KAMAL ENGINEERING CORPORATION[UNIT OF KEC INDUSTRI",
  "Starsight Power Utility Limited",
  "Loufan County Forestry Workstation",
  "Laizhou Xinzhongshui Environmental Protection Technology Co., Ltd.",
  "Haicheng Xinzhongshui Environmental Protection Technology Co., Ltd",
  "Multiple Proponents",
  "Qinghai Guanglv Landscaping Co., Ltd.",
  "Multiple Proponents",
  "Fujian Jinhu Power Co., Ltd.",
  "Multiple Proponents",
  "Convergence Energy Private Limited",
  "Credits transferred from approved GHG program",
  "Guangxi Ruirong Energy Technology Co., Ltd.",
  "ERA Assessoria e Projetos Ambientais e Agricolas Ltd.",
  "TAMILNADU SPINNING MILLS ASSOCIATION (TASMA)",
  "BK Alternatif Enerji Anonim Sirketi",
  "Gaizhou Xinzhongshui Environmental Protection Technology Co., Ltd.",
  "Forestal Azul S.A.",
  "Companhia de Fiação e Tecidos Santo Antonio",
  "G K Energy Marketers Pvt. Ltd",
  "The PURE PROJECT SAS",
  "EKI Energy Services Limited",
  "Fujian Shunchang State-owned Forest Farm",
  "Hefei Luyu Agricultural Technology Co., Ltd",
  "Hefei Luyu Agricultural Technology Co., Ltd",
  "Converted from other GHG program",
  "Mahogani Vishwa Agro Pvt Ltd",
  "M/s. Garden Court Distilleries Pvt. Ltd.",
  "Fundacion Bosque De Generaciones",
  "Multiple Proponents",
  "FORCERT Limited",
  "Multiple Proponents",
  "Carbon Sink Group s.r.l.",
  "Multiple Proponents",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "TAMILNADU SPINNING MILLS ASSOCIATION (TASMA)",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Crusoe Energy Systems, Inc.",
  "Investancia Paraguay S.A.",
  "Miller Forest Investment AG",
  "Guangzhou Zhongshui Recycling Environmental Protection Technology Co., Ltd.",
  "TAMILNADU SPINNING MILLS ASSOCIATION (TASMA)",
  "Ban.CO2 de Carbono Mestizo",
  "Converted from other GHG program",
  "MADERERA RIO ACRE SAC",
  "Zhuzhou Xinzhongshui Environmental Protection Technology Co., Ltd.",
  "Hefei Luyu Agricultural Technology Co., Ltd",
  "TASC Ltd.",
  "Hefei Luyu Agricultural Technology Co., Ltd",
  "Hefei Luyu Agricultural Technology Co., Ltd",
  "Multiple Proponents",
  "A-Gas South Africa",
  "Nectar Lifesciences Ltd",
  "African Parks RCA",
  "Converted from other GHG program",
  "Forest First Colombia SAS",
  "Multiple Proponents",
  "EKI Energy Services Limited",
  "Lianyuan Xinzhongshui Environmental Protection Technology Co., Ltd.",
  "EKI Energy Services Limited",
  "ALLCOT AG",
  "Tamilnadu Petroproducts Limited",
  "Nanjing Langtong Commercial Real Estate Operation Management Co. Ltd.",
  "C-Quest Capital SGT Asia Stoves Private Limited",
  "C-Quest Capital SGT Asia Stoves Private Limited",
  "Multiple Proponents",
  "Ziyang Oasis Xinzhongshui Environmental Protection Technology Co. Ltd.",
  "Multiple Proponents",
  "Thai Wah Public Company Limited",
  "Hainan Lingao Qinghong Environmental Protection Technology Co., Ltd.",
  "Fujian Tianyi Renewable Energy Technology & Utiliz",
  "Three Wheels United Inc.",
  "EKI Energy Services Limited",
  "Reforestadora Cumare S.A.S.",
  "SDG 13 Ventures Pte. Ltd.",
  "Skyridge Farms",
  "Multiple Proponents",
  "Gansu Founder Energy Conservation Sci&Tech Service Co., Ltd.",
  "African Parks Benin (A Branch of African Parks Network)",
  "Multiple Proponents",
  "China Resources Wind Power(Shantou) Co.Ltd",
  "OBEN EAC S.A",
  "Multiple Proponents",
  "Carbon Sink Group s.r.l.",
  "Sustainability Investment Promotion and Development Joint Stock Company",
  "EKI Energy Services Limited",
  "Bhoruka Power Corporation Limited",
  "BRCARBON SERVIÇOS AMBIENTAIS LTDA",
  "Department of Forests and Wildlife Preservation, Punjab",
  "Saving Nature, Inc.",
  "Climate Neutral Group.",
  "Multiple Proponents",
  "SAIFF Hold Co. Ltd.",
  "Sustainability Investment Promotion and Development Joint Stock Company",
  "Multiple Proponents",
  "Mungcharoen Green Power Co., Ltd. (MGP)",
  "AMG Advanced Metallurgical Group N.V.",
  "JustaStoveWorks LLC",
  "Multiple Proponents",
  "Instituto Homem Pantaneiro",
  "Multiple Proponents",
  "Lingshui County Forestry Bureau",
  "Yingjing County State-owned Assets Management Co., Ltd",
  "M/s. VRL Logistics Limited",
  "Beijing Qianyuhui Environmental Technology Co., Ltd.",
  "Multiple Proponents",
  "Veolia",
  "Pike Carbosur S.A.",
  "Rubber Reseach Institute of Sri Lanka",
  "China Resources Wind Power (Yantai) Co. Ltd",
  "Sustainability Investment Promotion and Development Joint Stock Company",
  "Zhijiang Xinzhongshui Chufeng Environmental Protection Technology Co., Ltd",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "413 Environmental, LLC",
  "Zero Carbon Holdings, LLC",
  "Parc Eolien Taiba N’Diaye S.A.U",
  "R.S.J.Ozone Private Limited",
  "Grow Indigo Private Ltd",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Tradewater International SRL",
  "EKI Energy Services Limited",
  "Panguana Stiftung",
  "Converted from other GHG program",
  "Diebu Niaojiaga Hydropower Development Co, Ltd",
  "JINDAL ALUMINIUM LIMITED",
  "Converted from other GHG program",
  "EnviroServ Waste Management",
  "Guangxi Liyuanbao Science and Technology Co., Ltd.",
  "EKI Energy Services Limited",
  "Multiple Proponents",
  "Fujian Qingliu Forestry Co., Ltd.",
  "Brightspark Energy Pvt. Ltd.",
  "Brightspark Energy Pvt. Ltd.",
  "Permian Malaysia SDN. BHD",
  "Sukhbir Agro Energy Limited",
  "UNITAN SAICA",
  "Quadriz Paraguay S.A.",
  "Lingbi Chenxin Green Industry Development Co., Ltd",
  "Guizhou Panjiang CBM Development&Utilization Co., Ltd.",
  "Burundi Quality Stoves S.A.",
  "Community Markets for Conservation (COMACO)",
  "Tombwe Processing Limited",
  "Huasheng Tianya Cement Co., Ltd.",
  "Multiple Proponents",
  "Multiple Proponents",
  "Guizhou Zhongjian’yangchen Energy Investment Co., Ltd.",
  "Komaza Group Inc.",
  "Guizhou Zhongjian’yangchen Energy Investment Co., Ltd.",
  "Guizhou Zhongjian’yangchen Energy Investment Co., Ltd.",
  "Guizhou Zhongjian’yangchen Energy Investment Co., Ltd.",
  "Chongqing Gengfang Agricultural Development Co., Ltd.",
  "COROMANDEL ELECTRIC CO LTD",
  "Chongqing Gengfang Agricultural Development Co., Ltd.",
  "Chongqing Gengfang Agricultural Development Co., Ltd.",
  "Chongqing Gengfang Agricultural Development Co., Ltd.",
  "Chongqing Gengfang Agricultural Development Co., Ltd.",
  "Chongqing Gengfang Agricultural Development Co., Ltd.",
  "Wanjiang New Energy Group Co., Ltd.",
  "OBEN EAC S.A",
  "Multiple Proponents",
  "Xingshan County Economic Development Investment Co., Ltd.",
  "Multiple Proponents",
  "Tektuð Elektrik Üretim A.Þ.",
  "One Carbon World Ltd.",
  "Multiple Proponents",
  "Norteak Nicaragua S.A.",
  "Arctica Partners",
  "Bhoruka Power Corporation Limited",
  "Guizhou Qianhe Carbon Technology Co., Ltd",
  "Guizhou Qianhe Carbon Technology Co., Ltd",
  "Sri Sai AAC Blocks",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Brightspark Energy Pvt. Ltd.",
  "Brightspark Energy Pvt. Ltd.",
  "Wanjiang New Energy Group Co., Ltd.",
  "Multiple Proponents",
  "Multiple Proponents",
  "Longjing State owned Sanhe Forest Farm",
  "Sree Sai Rama Industries",
  "EKI Energy Services Limited",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "BALKRISHNA INDUSTRIES LIMITED",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "UpEnergy Group",
  "Mo’erdaoga Forest Industry Co. LTD, China Inner Mongolia Forest Industry Group",
  "UpEnergy Group",
  "Community Carbon",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "C . J . SHAH & CO",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Salora International Limited",
  "Korea Carbon Management Ltd",
  "Fujian Aonong Biological Technology Group Incorporation Limited",
  "Fujian Aonong Biological Technology Group Incorporation Limited",
  "Yizheng Zhongxing Environmental Protection Technology Co., Ltd.",
  "Multiple Proponents",
  "Brightspark Energy Pvt. Ltd.",
  "Jiangxi Zhengbang Technology Co. Ltd",
  "Jiangxi Zhengbang Technology Co. Ltd",
  "Kangping County Liaobei Seedling Development Co., Ltd.",
  "Nanpanjiang Forestry Bureau of Yunnan Province",
  "Origin Energy Electricity Limited",
  "Ensemble Infrastructure India Limited",
  "Brightspark Energy Pvt. Ltd.",
  "Brightspark Energy Pvt. Ltd.",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Shuangbaotai Animal Husbandry Group Co., Ltd.",
  "Multiple Proponents",
  "BluSmart Mobility Private Limited",
  "Ecológica Assessoria Ltda.",
  "Euro Vistaa (India) Limited",
  "WeAct Pty Ltd",
  "C-Quest Capital Malaysia Global Stoves Limited",
  "C-Quest Capital Malaysia Global Stoves Limited",
  "Multiple Proponents",
  "Multiple Proponents",
  "Arxan Forest Industry Co. LTD, China Inner Mongolia Forest Industry Group",
  "Credits transferred from approved GHG program",
  "State owned Gaoyanzi Forest Farm in Jianshi County",
  "Christchurch City Council",
  "Chongqing Fuguan Investment Co., Ltd.",
  "A’longshan Forest Industry Co. LTD, China Inner Mongolia Forest Industry Group",
  "Multiple Proponents",
  "Yanting Agricultural Tourism Investment Management Co., Ltd.",
  "Solynta Energy Limited",
  "Ambipar Group",
  "Dahua Yao Autonomous County State owned Assets Investment Management Co., Ltd.",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "Xingren Lishuping State owned Forest Farm",
  "Three Gorges New Energy Diaobingshan Wind Power Co., Ltd.",
  "Multiple Proponents",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "eThekweni municipality cleansing & solid waste",
  "Multiple Proponents",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "EcoGas Asia Limited",
  "EcoGas Asia Limited",
  "EcoGas Asia Limited",
  "EcoGas Asia Limited",
  "Regency Aquaelectro & Motelresorts Ltd.",
  "DEKON FOOD AND AGRICULTURE GROUP",
  "DEKON FOOD AND AGRICULTURE GROUP",
  "Multiple Proponents",
  "Xingren Lishuping State owned Forest Farm",
  "El Retiro S.A.",
  "Multiple Proponents",
  "Guangzhou LEO-KING Environmental Technology Co., Ltd.",
  "Zhangye Academy of Forestry Sciences",
  "DelAgua Health Rwanda (Voluntary) Limited.",
  "RAKESH BUILDERS | DEVELOPERS",
  "Saving Nature, Inc.",
  "Mooh Cooperative",
  "MAHINDRA WASTE TO ENERGY SOLUTIONS LTD.",
  "Green Solutions",
  "EKI Energy Services Limited",
  "Multiple Proponents",
  "Gujarat Forest Department",
  "Gujarat Forest Department",
  "Gujarat Forest Department",
  "Gujarat Forest Department",
  "Jilin Longyuan Wind Power Co., Ltd.",
  "Kanaka Management Services Private Limited",
  "MOOTRAL SA",
  "Yonggui Energy Development Co., Ltd. Xintian Branch",
  "3R Management (P) Ltd",
  "Interholco AG",
  "OQ Trading Limited",
  "OQ Trading Limited",
  "Hangzhou Chunmeng Agricultural Technology Co., Ltd",
  "Longyuan (Zhangjiakou) Wind Power Co., Ltd.",
  "Hangzhou Chunmeng Agricultural Technology Co., Ltd",
  "Hangzhou Chunmeng Agricultural Technology Co., Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Luoyang Huanghe Tongli Cement Co., Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "UpEnergy Group",
  "Xinxiang Pingyuan Tongli Cement Co., Ltd",
  "Suzano SA",
  "WeAct Pty Ltd",
  "Worldview International Foundation",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "COzero",
  "Zhumadian Yulong Tongli Cement Co., Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Enercon (India) Limited",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "DCM Shriram Consolidated Limited",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "Korea Carbon Management Ltd",
  "POWA S.A",
  "Liupanshui Tuoyuan Group Co. Ltd.",
  "Beijing Yonder Environmental Energy Technology Co., Ltd",
  "Lijin Huayu Breeding Swine Co., Ltd.",
  "CropZone Agro Forestry Private Limited",
  "WeForest ASBL",
  "MicroEnergy Credits Corp",
  "MicroEnergy Credits Corp",
  "MicroEnergy Credits Corp",
  "MicroEnergy Credits Corp",
  "MicroEnergy Credits Corp",
  "Zhenning Yuefeng Hydro Power Development Co. Ltd.",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "BlueMX Mangrove A.C.",
  "Guizhou Zhongjian’yangchen Energy Investment Co., Ltd.",
  "Muyuan Foods Co., Ltd.",
  "Guizhou Zhongjian’yangchen Energy Investment Co., Ltd.",
  "Muyuan Foods Co., Ltd.",
  "Guizhou Zhongjian’yangchen Energy Investment Co., Ltd.",
  "Gaomi Nanyang Taikefeng Environmental Protection Techonology Co., Ltd.",
  "Guizhou Xinzhanxin Agricultural Technology Co., Ltd.",
  "KRBL LIMITED",
  "Guizhou Xinzhanxin Agricultural Technology Co., Ltd.",
  "Guizhou Xinzhanxin Agricultural Technology Co., Ltd.",
  "Burqin Jinhua Urban Construction and Investment Co.Ltd.",
  "Guizhou Xinzhanxin Agricultural Technology Co., Ltd.",
  "Guizhou Xinzhanxin Agricultural Technology Co., Ltd.",
  "Guizhou Xinzhanxin Agricultural Technology Co., Ltd.",
  "Guizhou Xinzhanxin Agricultural Technology Co., Ltd.",
  "Kanaka Management Services Private Limited",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "Energest SA",
  "Multiple Proponents",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "Xuancheng Nanyang Biotechnology Co., Ltd",
  "Yongcheng Hongqiao Environmental Protection Energy Co. Ltd.",
  "Xuancheng Nanyang Biotechnology Co., Ltd",
  "Om Organic Cotton Pvt. Ltd.",
  "SDG 13 Ventures Pte. Ltd.",
  "Howrah Mills Co. Ltd.,",
  "Multiple Proponents",
  "Ecológica Assessoria Ltda.",
  "Multiple Proponents",
  "NFC Green SpA",
  "NFC Green SpA",
  "Muyuan Foods Co., Ltd.",
  "Wuhan Haimingsen Environmental Protection Technology Co., Ltd.",
  "Future Carbon International LLC",
  "Henan Lehuijia Agricultural Technology Co., Ltd.",
  "Multiple Proponents",
  "Binzhou Topigs Norsvin Breeding Swine Co., Ltd. Binhai Branch",
  "New Forests Company Holdings I Limited",
  "Fangchenggang Fangcheng District Jinsui Agriculture Investment Co., Ltd",
  "SDG 13 Ventures Pte. Ltd.",
  "Zhangye Academy of Forestry Sciences",
  "C-Quest Capital SGM ZM Stoves Private Limited",
  "C-Quest Capital SGM ZM Stoves Private Limited",
  "Boomitra Inc",
  "C-Quest Capital SGM ZM Stoves Private Limited",
  "Boomitra Inc",
  "DLF Limited",
  "C-Quest Capital SGM ZM Stoves Private Limited",
  "Wuhan Haimingsen Environmental Protection Technology Co., Ltd.",
  "Wuhan Haimingsen Environmental Protection Technology Co., Ltd.",
  "Envision Energy Co. Ltd",
  "Wuhan Haimingsen Environmental Protection Technology Co., Ltd.",
  "Guizhou Jinyan Energy Technology Co., Ltd.",
  "Carbon Sink Group s.r.l.",
  "Wuhan Haimingsen Environmental Protection Technology Co., Ltd.",
  "Gansu Diantou Darong Shimenping Power Generation",
  "Lee County Board of County Commissioners SW Div.",
  "Wuhan Haimingsen Environmental Protection Technology Co., Ltd.",
  "Wuhan Haimingsen Environmental Protection Technology Co., Ltd.",
  "Wuhan Haimingsen Environmental Protection Technology Co., Ltd.",
  "Wuhan Haimingsen Environmental Protection Technology Co., Ltd.",
  "Wuhan Haimingsen Environmental Protection Technology Co., Ltd.",
  "Wuhan Haimingsen Environmental Protection Technology Co., Ltd.",
  "Multiple Proponents",
  "Wuhan Haimingsen Environmental Protection Technology Co., Ltd.",
  "YASHWANTRAO MOHITE KRISHNA SSK LTD, RETHARE BK.",
  "Hangzhou Chunmeng Agricultural Technology Co., Ltd",
  "Hangzhou Chunmeng Agricultural Technology Co., Ltd",
  "Hangzhou Chunmeng Agricultural Technology Co., Ltd",
  "Guizhou Jinyan Energy Technology Co., Ltd.",
  "Ban.CO2 de Carbono Mestizo",
  "Hangzhou Chunmeng Agricultural Technology Co., Ltd",
  "Hangzhou Chunmeng Agricultural Technology Co., Ltd",
  "Hangzhou Chunmeng Agricultural Technology Co., Ltd",
  "Hangzhou Chunmeng Agricultural Technology Co., Ltd",
  "Hangzhou Chunmeng Agricultural Technology Co., Ltd",
  "Vayu (Project 1) private limited",
  "Jilin Forest Industry Environmental Technology Co., Ltd.",
  "Outreach Projects Pvt. Ltd.",
  "Sustainability Investment Promotion and Development Joint Stock Company",
  "Sustainability Investment Promotion and Development Joint Stock Company",
  "Sustainability Investment Promotion and Development Joint Stock Company",
  "Shenzhen Vanke Millennial Development Co. Ltd.",
  "EcoPlanet Bamboo Group",
  "EcoPlanet Bamboo Group",
  "Sukhbir Agro Energy Limited",
  "NE Climate A/S",
  "Multiple Proponents",
  "Multiple Proponents",
  "Multiple Proponents",
  "Boomitra Inc",
  "Hunan Cili Jiangya Forest Farm",
  "Shiyan Lvxin Guangjing New Energy Co., Ltd.",
  "Yunshui Guangdong Environmental Protection Co., Ltd.",
  "EKI Energy Services Limited",
  "Jiangsu Longyuan Wind Power Co., Ltd.",
  "Multiple Proponents",
  "EKI Energy Services Limited",
  "Multiple Proponents",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "co2balance UK",
  "Gansu Jinshui Power Development Co., Ltd",
  "Multiple Proponents",
  "Kangping Xinwang agriculture and animal husbandry Co., Ltd.",
  "Not Hot Environmental Solutions",
  "SCB Brokers LLC",
  "C-Quest Capital Stoves Asia Limited",
  "C-Quest Capital Stoves Asia Limited",
  "Wuhan Jianghuan OneCarbon Energy Development Co., Ltd.",
  "EcoPlanet Bamboo Group",
  "Shillong Bamboo",
  "Poonawalla Fincorp Ltd",
  "Multiple Proponents",
  "Multiple Proponents",
  "Converted from other GHG program",
  "Carbon Project Australia Pty Ltd",
  "Carbon Project Australia Pty Ltd",
  "AGS Carbon Advisory",
  "Gujarat JHM Hotels Ltd",
  "Acacia Batéké Capital",
  "EKI Energy Services Limited",
  "BlueMX Mangrove A.C.",
  "BlueMX Mangrove A.C.",
  "BlueMX Mangrove A.C.",
  "Multiple Proponents",
  "ACB (INDIA) LIMITED",
  "Multiple Proponents",
  "C-Quest Capital SG Angola Stoves Private Limited",
  "Converted from other GHG program",
  "Green Solutions",
  "Likano Project Development GmbH",
  "Ozinga",
  "Multiple Proponents",
  "Multiple Proponents",
  "SunCulture",
  "Aishwarya Rai",
  "Solika Energy Pvt. Ltd.",
  "Pike Carbosur S.A.",
  "SAEL Limited",
  "Multiple Proponents",
  "Blue Ocean Barns",
  "Brascarbon Consultoria Projetos e Representação S/A",
  "Multiple Proponents",
  "Hefeng Taoyuan Hydropower Co., Ltd.",
  "Wuling Power Corporation Ltd",
  "Aishwarya Rai",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "Jiangxi Huapeng Industrial Co., Ltd",
  "Multiple Proponents",
  "Barry Callebaut Sourcing AG",
  "Will Solutions Inc",
  "Tongxu Agro-pastoral Co., Ltd.",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "AGS Carbon Advisory",
  "EKI Energy Services Limited",
  "M/s Umedica laboratories Private Limited",
  "Converted from other GHG program",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "The PURE PROJECT SAS",
  "Native, a Public Benefit Corporation",
  "Native, a Public Benefit Corporation",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "Jiangsu REO-ECO New Material Tech Co., Ltd",
  "Mosaic Forest Management Corporation",
  "Multiple Proponents",
  "Rajesh Construction Company Limited",
  "CSD Water Service Co., Ltd.",
  "Carbon Projects LLC",
  "Guizhou Yuanda Carbon Forestry Development Co., Ltd",
  "CSD Water Service Co., Ltd.",
  "MOOV TECHNOLOGY (S) PTE. LTD.",
  "Savita Oil Technologies Limited",
  "Multiple Proponents",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "Centrais Eletricas do Rio Jordao S.A. - ELEJOR",
  "GAIA s.r.l. Società Agricola",
  "Easy Volt Brasil",
  "PERSISTENTCLIMATE INDIA PRIVATE LIMITED",
  "Multiple Proponents",
  "Nanyang Dongjia Agriculture and Animal Husbandry Development Co., Ltd.",
  "Hangzhou Chunmeng Agricultural Technology Co., Ltd",
  "Future Carbon Consultoria e Projetos Florestais LTDA",
  "VVD AND SONS PRIVATE LIMITED",
  "OnePlanet Ltda",
  "Sustainability Investment Promotion and Development Joint Stock Company",
  "Greneity Infocom Service Pvt Ltd",
  "Multiple Proponents",
  "Brascarbon Consultoria Projetos e Representação S/A",
  "BlueMX Mangrove A.C.",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "Multiple Proponents",
  "KS Oils Ltd",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "Fangcheng County Pengmai Agriculture and animal husbandry development Co., Ltd.",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "Multiple Proponents",
  "Multiple Proponents",
  "Amiha Agro Private Limited",
  "Multiple Proponents",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "Daegu Metropolitan City",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "Multiple Proponents",
  "EcoPlanet Bamboo Group",
  "180.Holdings B.V.",
  "Guangxi Tianyang Nali Investment & Development Co., Ltd",
  "Multiple Proponents",
  "Multiple Proponents",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "Guizhou Haifengjufu Agricultural Science and Technology Co., Ltd",
  "Yamuna Power & Infrastructure Limited",
  "M/S Citadel ISWM Project Singrauli Pvt Limited",
  "Guizhou Haifengjufu Agricultural Science and Technology Co., Ltd",
  "Guizhou Yuanda Carbon Forestry Development Co., Ltd",
  "Guizhou Haifengjufu Agricultural Science and Technology Co., Ltd",
  "Guizhou Haifengjufu Agricultural Science and Technology Co., Ltd",
  "Guizhou Haifengjufu Agricultural Science and Technology Co., Ltd",
  "Guizhou Yuanda Carbon Forestry Development Co., Ltd",
  "Guizhou Haifengjufu Agricultural Science and Technology Co., Ltd",
  "Guizhou Haifengjufu Agricultural Science and Technology Co., Ltd",
  "Guizhou Yuanda Carbon Forestry Development Co., Ltd",
  "Multiple Proponents",
  "Guizhou Haifengjufu Agricultural Science and Technology Co., Ltd",
  "Guizhou Yuanda Carbon Forestry Development Co., Ltd",
  "Multiple Proponents",
  "Nurture Agtech Private Limited",
  "Global Emissionairy, LLC",
  "Kotyark Industries Limited",
  "GSG Cleantech Private Limited",
  "Forestaciones Operativas de México S.A. de C.V.",
  "Spectra Solar Park Limited",
  "ERA INFRA ENGINEERING LTD",
  "Yunnan Ruihan Agricultural Technology Development Co., Ltd.",
  "Henan Jinquan New Energy Co. LTD",
  "Yunnan Ruihan Agricultural Technology Development Co., Ltd.",
  "Yunnan Ruihan Agricultural Technology Development Co., Ltd.",
  "Yunnan Ruihan Agricultural Technology Development Co., Ltd.",
  "Guizhou Yuanda Carbon Forestry Development Co., Ltd",
  "Yunnan Ruihan Agricultural Technology Development Co., Ltd.",
  "Yunnan Ruihan Agricultural Technology Development Co., Ltd.",
  "Yunnan Ruihan Agricultural Technology Development Co., Ltd.",
  "Fair Climate Fund India Private Limited",
  "ERA LANDMARKS LTD",
  "Pingmei Huanglong New Energy Co., Ltd",
  "Multiple Proponents",
  "Multiple Proponents",
  "Multiple Proponents",
  "Guangzhou Iceberg Environmental Consulting Services Co., Ltd.",
  "Carbon Upcycling Technologies",
  "Harbin Huanyu Agricultural Technology Co., Ltd.",
  "Harbin Huanyu Agricultural Technology Co., Ltd.",
  "Harbin Huanyu Agricultural Technology Co., Ltd.",
  "Harbin Huanyu Agricultural Technology Co., Ltd.",
  "Harbin Huanyu Agricultural Technology Co., Ltd.",
  "Multiple Proponents",
  "China Carbon Sinks Holdings Limited",
  "China Carbon Sinks Holdings Limited",
  "Qingfeng Agro-pastoral Co., Ltd.",
  "Cenxi Rural Investment Co ., Ltd",
  "Luyi Agro-pastoral Co., Ltd.",
  "Jiangxi Tianye Biotechnology Limited",
  "Macaohe Hydro Power Development in Songtao County",
  "Shangcai Agro-pastoral Co., Ltd.",
  "Xiping Agro-pastoral Co., Ltd.",
  "Shangcai Agro-pastoral Co., Ltd.",
  "Huaxian Agro-pastoral Co., Ltd.",
  "Huaxian Agro-pastoral Co., Ltd.",
  "Muyuan Foods Co., Ltd.",
  "Chao’er Forest Bureau of Inner Mongolia Autonomous Region",
  "Guangzhou Kaifeng Agricultural Technology Co., Ltd.",
  "Multiple Proponents",
  "Yunnan Ruihan Agricultural Technology Development Co., Ltd.",
  "CARBON CREDITS CONSULTING S.R.L.",
  "Blue Forest",
  "Multiple Proponents",
  "Multiple Proponents",
  "Dake Group",
  "Jiangxi Tianye Biotechnology Limited",
  "Harbin Huanyu Agricultural Technology Co., Ltd.",
  "Harbin Huanyu Agricultural Technology Co., Ltd.",
  "MODELAMA EXPORTS LTD",
  "Harbin Huanyu Agricultural Technology Co., Ltd.",
  "SDG 13 Ventures Pte. Ltd.",
  "Ecológica Assessoria Ltda.",
  "Jiangxi Tianye Biotechnology Limited",
  "Green Solutions",
  "everi GmbH",
  "Value Network Ventures Advisory Services Pvt. Ltd.",
  "Greneity Infocom Service Pvt Ltd",
  "Greneity Infocom Service Pvt Ltd",
  "Greneity Infocom Service Pvt Ltd",
  "AGRO INDUSTRIAL PARAMONGA SAA",
  "Greneity Infocom Service Pvt Ltd",
  "Greneity Infocom Service Pvt Ltd",
  "SDG 13 Ventures Pte. Ltd.",
  "AERA Group",
  "Yantai Xinhao agriculture and animal husbandry Co., Ltd.",
  "Xin Ran Agriculture and Animal Husbandry Co. Ltd.",
  "ReforesTerra – Restauração de Ecossistemas Florestais Ltda",
  "Godrej Agrovet Limited",
  "Multiple Proponents",
  "Recast Energy LLC",
  "Hangzhou Chaoteng Energy Technology Co., Ltd",
  "Ujjain Smart City Limited",
  "Multiple Proponents",
  "Biowin Agro Research",
  "Multiple Proponents",
  "Harbin Huanyu Agricultural Technology Co., Ltd.",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "AIDER",
  "3Degrees Group, Inc",
  "Suminter India Organics",
  "Harbin Huanyu Agricultural Technology Co., Ltd.",
  "POWA S.A",
  "Guangxi Meiluo Agricultural Science and Technology Co Ltd.",
  "Guangxi Meiluo Agricultural Science and Technology Co Ltd.",
  "Guangxi Meiluo Agricultural Science and Technology Co Ltd.",
  "Guangxi Meiluo Agricultural Science and Technology Co Ltd.",
  "Guangxi Meiluo Agricultural Science and Technology Co Ltd.",
  "Guangxi Meiluo Agricultural Science and Technology Co Ltd.",
  "Guangxi Meiluo Agricultural Science and Technology Co Ltd.",
  "Gansu CGNPC Wind Power Co., Ltd",
  "Guangxi Meiluo Agricultural Science and Technology Co Ltd.",
  "Guangxi Meiluo Agricultural Science and Technology Co Ltd.",
  "Guangxi Meiluo Agricultural Science and Technology Co Ltd.",
  "Guangxi Meiluo Agricultural Science and Technology Co Ltd.",
  "Guangxi Meiluo Agricultural Science and Technology Co Ltd.",
  "Harbin Huanyu Agricultural Technology Co., Ltd.",
  "PARACEL SA",
  "Guangzhou Iceberg Environmental Consulting Services Co., Ltd.",
  "C-Quest Capital Stoves Asia Limited",
  "C-Quest Capital Stoves Asia Limited",
  "Luara Indústria e Comércio de Materiais Ltda",
  "IL&FS Ecosmart Limited",
  "C-Quest Capital Stoves Asia Limited",
  "Xin Ran Agriculture and Animal Husbandry Co. Ltd.",
  "Xin Ran Agriculture and Animal Husbandry Co. Ltd.",
  "Yulong State Owned Capital Investment and Operation Co., Ltd.",
  "Sustainability Investment Promotion and Development Joint Stock Company",
  "C-Quest Capital Stoves Asia Limited",
  "C-Quest Capital Stoves Asia Limited",
  "CarbonCure Technologies Inc.",
  "Decarbonology Pty Ltd",
  "C-Quest Capital Stoves Asia Limited",
  "Interocean Shipping (India) Pvt. Ltd.",
  "GAIA s.r.l. Società Agricola",
  "Guangzhou Iceberg Environmental Consulting Services Co., Ltd.",
  "Indigo Agriculture Europe GmbH",
  "Çağlayanlar Biyogaz Elektrik Üretim Sanayi ve Ticaret Limited Şirketi",
  "Multiple Proponents",
  "IED Invest Cameroun",
  "3Degrees Group, Inc",
  "Multiple Proponents",
  "Multiple Proponents",
  "Yingkou Canvest Environmental Power Company Limited.",
  "Multiple Proponents",
  "Oasis Science &Technology Development( Beijing) Co., Ltd",
  "InnoCSR Co.Ltd.",
  "Guangzhou Kaifeng Agricultural Technology Co., Ltd.",
  "Indústria Cerâmica Panorama LTDA.",
  "SAUDI ARABIAN OIL CO.(ARAMCO)",
  "Hainan Sunsea Ecological Agriculture Technology Co., Ltd.",
  "Hainan Sunsea Ecological Agriculture Technology Co., Ltd.",
  "Hainan Sunsea Ecological Agriculture Technology Co., Ltd.",
  "Hainan Sunsea Ecological Agriculture Technology Co., Ltd.",
  "Oasis Science &Technology Development( Beijing) Co., Ltd",
  "Oasis Science &Technology Development( Beijing) Co., Ltd",
  "Oasis Science &Technology Development( Beijing) Co., Ltd",
  "Core CarbonX Solutions Pvt. Ltd.",
  "Guangzhou Iceberg Environmental Consulting Services Co., Ltd.",
  "Multiple Proponents",
  "Core CarbonX Solutions Pvt. Ltd.",
  "Juno Carbon Investment & Environmental Technology (Beijing) Co., Ltd.",
  "Rivulis",
  "Core CarbonX Solutions Pvt. Ltd.",
  "Sustainability Investment Promotion and Development Joint Stock Company",
  "Greneity Infocom Service Pvt Ltd",
  "CropZone Agro Forestry Private Limited",
  "EKI Energy Services Limited",
  "Sichuan Ebian Lugouhe Hydroelectricity Co., Ltd.",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "Sichuan Ebian Jindong Tiji Hydropower Co., Ltd.",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "Livelihoods Fund SICAV SIF",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "Yanbian Mingyuan Electric Power Co., Ltd",
  "Guangxi Yuebao Agriculture and Animal Husbandry Co., Ltd",
  "Guangxi Yuebao Agriculture and Animal Husbandry Co., Ltd",
  "Guangxi Yuebao Agriculture and Animal Husbandry Co., Ltd",
  "Guangxi Yuebao Agriculture and Animal Husbandry Co., Ltd",
  "Guangxi Yuebao Agriculture and Animal Husbandry Co., Ltd",
  "Guangxi Yuebao Agriculture and Animal Husbandry Co., Ltd",
  "Guangxi Yuebao Agriculture and Animal Husbandry Co., Ltd",
  "Guangxi Yuebao Agriculture and Animal Husbandry Co., Ltd",
  "everi GmbH",
  "Infrastructure Development Company Limited",
  "Panzhihua Reshuihe Power Development Co., Ltd.",
  "Fundatia Conservation Carpathia",
  "MATA NATIVA DO BRASIL ASSESSORIA E CONSULTORIA AMBIENTAL LTDA",
  "HARI BHARI MORADABAD WASTE MANAGEMENT PRIVATE LIMITED",
  "Multiple Proponents",
  "Kanaka Management Services Private Limited",
  "Himadri Energy International Private Limited",
  "Multiple Proponents",
  "GOVARDHANNATHJI ENERGIES LLP",
  "Mahanadi Compressed Biogas (CBG) Private Limited",
  "Aither Group SA",
  "Social Carbon Company",
  "co2balance",
  "Boomitra Inc",
  "Fundacion Ayuda en Acción",
  "Multiple Proponents",
  "Multiple Proponents",
  "Multiple Proponents",
  "Multiple Proponents",
  "The Green Branch",
  "Multiple Proponents",
  "Wutuhe Hydro Power Development Co.,Ltd of Liupansh",
  "3Degrees Group, Inc",
  "3Degrees Group, Inc",
  "3Degrees Group, Inc",
  "Greneity Infocom Service Pvt Ltd",
  "Multiple Proponents",
  "AGS Carbon Advisory",
  "Future Carbon Consultoria e Projetos Florestais LTDA",
  "Xin Ran Agriculture and Animal Husbandry Co. Ltd.",
  "Shenzhen PhasCon Technologies Co.,Ltd.",
  "Hainan Sunsea Ecological Agriculture Technology Co., Ltd.",
  "MSPL Limited",
  "Hainan Sunsea Ecological Agriculture Technology Co., Ltd.",
  "Hainan Sunsea Ecological Agriculture Technology Co., Ltd.",
  "Guangzhou Kaifeng Agricultural Technology Co., Ltd.",
  "Hainan Sunsea Ecological Agriculture Technology Co., Ltd.",
  "Guangzhou Kaifeng Agricultural Technology Co., Ltd.",
  "Guangzhou Kaifeng Agricultural Technology Co., Ltd.",
  "Hainan Sunsea Ecological Agriculture Technology Co., Ltd.",
  "Hainan Sunsea Ecological Agriculture Technology Co., Ltd.",
  "Nehe Hansenyuan Forestry Investment Co., Ltd",
  "National Forestry and Grassland Administration Northwest Investigation and Planning Institute",
  "Dori Alimentos LTDA.",
  "Guangzhou Kaifeng Agricultural Technology Co., Ltd.",
  "Multiple Proponents",
  "Multiple Proponents",
  "Multiple Proponents",
  "Beijing Hongyuan Chuangxin Energy Technology Co., Ltd",
  "Gansu Hongmao Agriculture Development Co.,Ltd",
  "C-Quest Capital Stoves Asia Limited",
  "C-Quest Capital Stoves Asia Limited",
  "C-Quest Capital Stoves Asia Limited",
  "Luquan Yulong Power Generation Co., Ltd.",
  "C-Quest Capital Stoves Asia Limited",
  "Arva Intelligence",
  "SIM Sustainable Investment Management",
  "Multiple Proponents",
  "Para Redd Projects Limited",
  "Tiger Conservation Foundation of Pench Tiger Reserve (TCFP)",
  "National Forestry and Grassland Administration Northwest Investigation and Planning Institute",
  "National Forestry and Grassland Administration Northwest Investigation and Planning Institute",
  "National Forestry and Grassland Administration Northwest Investigation and Planning Institute",
  "National Forestry and Grassland Administration Northwest Investigation and Planning Institute",
  "Cleanergy BV",
  "Boomitra Inc",
  "FORLIANCE GmbH",
  "FORLIANCE GmbH",
  "Bosques Amazonicos",
  "Varaha ClimateAg Private Limited",
  "Periyar Tiger Conservation Foundation (PTCF)",
  "Shenzhen PhasCon Technologies Co.,Ltd.",
  "Aben Recycling BV",
  "Suzano SA",
  "CIBO Technologies",
  "ANEW ENVIRONMENTAL, LLC",
  "B.B. Energy (Asia) Pte Ltd",
  "Himadri Energy International Private Limited",
  "Multiple Proponents",
  "Multiple Proponents",
  "Guangxi Meiluo Agricultural Science and Technology Co Ltd.",
  "Guangxi Yuebao Agriculture and Animal Husbandry Co., Ltd",
  "Houbensteyn milieu BV",
  "Multiple Proponents",
  "Multiple Proponents",
  "Matthias Graf von Westphalen",
  "CRX CARBONBANK PTE. LTD.",
  "Value Network Ventures Advisory Services Pvt. Ltd.",
  "Shanxi Qingze Yangguang Environmental Protection Technology Co., Ltd.",
  "Orizon Agriculture (Pty) Ltd",
  "Sichuan Tieqilishi Food Co., Ltd.",
  "B.V. Landgoed de Princepeel",
  "UpEnergy Group",
  "SDG 13 Ventures Pte. Ltd.",
  "Guangzhou Kaifeng Agricultural Technology Co., Ltd.",
  "Huoqiu huizhao Renewable Energy Technology Co., Ltd",
  "Multiple Proponents",
  "Praktijkcentrum Sterksel",
  "SIMFlor Ltd",
  "Guangxi Yuebao Agriculture and Animal Husbandry Co., Ltd",
  "Yunnan Ruihan Agricultural Technology Development Co., Ltd.",
  "Guizhou Haifengjufu Agricultural Science and Technology Co., Ltd",
  "Hunan Yueyang Huabanqiao Market Development Co., Ltd.",
  "Guangzhou Kaifeng Agricultural Technology Co., Ltd.",
  "Santai Shengda Development Co. LTD",
  "Guangzhou Kaifeng Agricultural Technology Co., Ltd.",
  "Guangzhou Kaifeng Agricultural Technology Co., Ltd.",
  "SHIRDI INDUSTRIES LTD",
  "Guangzhou Kaifeng Agricultural Technology Co., Ltd.",
  "Guangzhou Kaifeng Agricultural Technology Co., Ltd.",
  "Hubei Jiulong Recycling Technology Co., Ltd.",
  "Climate Neutral Group.",
  "Forestry Bureau of Tongyu County",
  "Sequoia Plantation Togo",
  "Hunan Yueyang Huabanqiao Market Development Co., Ltd.",
  "Hunan Yueyang Huabanqiao Market Development Co., Ltd.",
  "Multiple Proponents",
  "IL&FS Waste Management & Urban Services Limited",
  "Hunan Yueyang Huabanqiao Market Development Co., Ltd.",
  "Hunan Yueyang Huabanqiao Market Development Co., Ltd.",
  "Yunnan Ruihan Agricultural Technology Development Co., Ltd.",
  "Yunnan Ruihan Agricultural Technology Development Co., Ltd.",
  "ORIZON Valorização de Resíduos S.A.",
  "Guangzhou Kaifeng Agricultural Technology Co., Ltd.",
  "Shenzhen PhasCon Technologies Co.,Ltd.",
  "Yunnan Ruihan Agricultural Technology Development Co., Ltd.",
  "Yunnan Ruihan Agricultural Technology Development Co., Ltd.",
  "Multiple Proponents",
  "Yunnan Ruihan Agricultural Technology Development Co., Ltd.",
  "Yunnan Ruihan Agricultural Technology Development Co., Ltd.",
  "AGS Carbon Advisory",
  "AGS Carbon Advisory",
  "Guangzhou Kaifeng Agricultural Technology Co., Ltd.",
  "Guangzhou Kaifeng Agricultural Technology Co., Ltd.",
  "Guangzhou Kaifeng Agricultural Technology Co., Ltd.",
  "Guangzhou Kaifeng Agricultural Technology Co., Ltd.",
  "Guangzhou Kaifeng Agricultural Technology Co., Ltd.",
  "Land Life Company BV",
  "Chianan Industry Co. Ltd.",
  "Yunnan Ruihan Agricultural Technology Development Co., Ltd.",
  "Hunan Yueyang Huabanqiao Market Development Co., Ltd.",
  "Yunnan Ruihan Agricultural Technology Development Co., Ltd.",
  "Hunan Yueyang Huabanqiao Market Development Co., Ltd.",
  "Yunnan Ruihan Agricultural Technology Development Co., Ltd.",
  "Yunnan Ruihan Agricultural Technology Development Co., Ltd.",
  "Holmstone Pty. (Ltd.)",
  "WeForest ASBL",
  "PKPN Spinning Mills Pvt Ltd",
  "Multiple Proponents",
  "VN Foods & Beverages",
  "Multiple Proponents",
  "Guiyang Forestry Industry Development Co., Ltd",
  "Royal Government of Cambodia (RGC), Ministry of Environment",
  "Chuzhou huangqingwei Modern Agricultural Development Co., Ltd",
  "Gansu Hongmao Agriculture Development Co.,Ltd",
  "Gansu Hongmao Agriculture Development Co.,Ltd",
  "Gansu Hongmao Agriculture Development Co.,Ltd",
  "Gansu Hongmao Agriculture Development Co.,Ltd",
  "Hindustan Zinc Limited",
  "Gansu Hongmao Agriculture Development Co.,Ltd",
  "Gansu Hongmao Agriculture Development Co.,Ltd",
  "Gansu Hongmao Agriculture Development Co.,Ltd",
  "Gansu Hongmao Agriculture Development Co.,Ltd",
  "Gansu Hongmao Agriculture Development Co.,Ltd",
  "MARCA CONSTRUTORA E SERVIÇOS LTDA",
  "Hindustan Zinc Limited",
  "Multiple Proponents",
  "Future Carbon Consultoria e Projetos Florestais LTDA",
  "Multiple Proponents",
  "Multiple Proponents",
  "Shri Maa Marketing Private Limited",
  "Multiple Proponents",
  "Multiple Proponents",
  "Gansu Hongmao Agriculture Development Co.,Ltd",
  "Gansu Hongmao Agriculture Development Co.,Ltd",
  "Gujarat Fluorochemicals Limited (GFL)",
  "EKI Energy Services Limited",
  "Hunan Yueyang Huabanqiao Market Development Co., Ltd.",
  "Hunan Yueyang Huabanqiao Market Development Co., Ltd.",
  "Hunan Yueyang Huabanqiao Market Development Co., Ltd.",
  "Harbin Beigen Technology Co., Ltd.",
  "Harbin Beigen Technology Co., Ltd.",
  "Harbin Beigen Technology Co., Ltd.",
  "Harbin Beigen Technology Co., Ltd.",
  "Harbin Beigen Technology Co., Ltd.",
  "Harbin Beigen Technology Co., Ltd.",
  "Multiple Proponents",
  "Harbin Beigen Technology Co., Ltd.",
  "Harbin Beigen Technology Co., Ltd.",
  "Gansu Hongmao Agriculture Development Co.,Ltd",
  "Core CarbonX Solutions Pvt. Ltd.",
  "Core CarbonX Solutions Pvt. Ltd.",
  "Core CarbonX Solutions Pvt. Ltd.",
  "BOLLINENI CASTINGS AND STEEL LIMITED",
  "Core CarbonX Solutions Pvt. Ltd.",
  "Core CarbonX Solutions Pvt. Ltd.",
  "Envro Queen Private Limited",
  "Harbin Beigen Technology Co., Ltd.",
  "Core CarbonX Solutions Pvt. Ltd.",
  "Harbin Beigen Technology Co., Ltd.",
  "Harbin Beigen Technology Co., Ltd.",
  "MSPL Limited",
  "Core CarbonX Solutions Pvt. Ltd.",
  "Zhuhai Guohua Wonderful Wind Power Exploitation Co",
  "Welspun India Limited",
  "Emergent Ventures India Private Limited",
  "Kattegat S.R.L.",
  "Green Carbon Partners Pty Limited",
  "Core CarbonX Solutions Pvt. Ltd.",
  "CLS Enerji Üretim Sanayi ve Ticaret Limited Şirketi.",
  "EKI Energy Services Limited",
  "Kaypee Enterprises",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "Chhattisgarh Forest Department",
  "EKI Energy Services Limited",
  "CEMENTOS LIMA S.A.",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "Goodplanet",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "AMPSolar Generation Private Limited.",
  "Core CarbonX Solutions Pvt. Ltd.",
  "Multiple Proponents",
  "Multiple Proponents",
  "JBM FANALCA ENVIRONME NT MANAGEMENT PRIVATE LIMITED",
  "HDFC Sinpower Limited",
  "Chongqing Xinchu Carbon Technology Co. Ltd",
  "Greenovation Sustainability Solutions Private Limited",
  "Longyuan (Baotou) Wind Power Co., Ltd.",
  "SAUNTA GAUNTA FOUNDATION",
  "Indian Farm Forestry Development Co-operative Ltd.",
  "Core CarbonX Solutions Pvt. Ltd.",
  "Greenply Industries Limited (GIL)",
  "Tata Power Renewable Energy Limited",
  "Sun Source Energy Private Limited.",
  "Core CarbonX Solutions Pvt. Ltd.",
  "Sun Source Energy Private Limited.",
  "Bionersis S.A.",
  "Infinite Solutions",
  "Core CarbonX Solutions Pvt. Ltd.",
  "Shivbhadra Agro Private LTD",
  "Core CarbonX Solutions Pvt. Ltd.",
  "Fundación Biodiversa Colombia (FBC)",
  "Core CarbonX Solutions Pvt. Ltd.",
  "Purncare Carbon Mitigation Solutions Private Limited",
  "Core CarbonX Solutions Pvt. Ltd.",
  "Parthcrete Green Concrete Solutions LLP",
  "Indian Wind Power Association",
  "Infinite Solutions",
  "Infinite Solutions",
  "Infinite Environmental Solutions LLP",
  "Multiple Proponents",
  "Core CarbonX Solutions Pvt. Ltd.",
  "Core CarbonX Solutions Pvt. Ltd.",
  "Core CarbonX Solutions Pvt. Ltd.",
  "Core CarbonX Solutions Pvt. Ltd.",
  "Indian Wind Power Association",
  "Core CarbonX Solutions Pvt. Ltd.",
  "Core CarbonX Solutions Pvt. Ltd.",
  "Core CarbonX Solutions Pvt. Ltd.",
  "Core CarbonX Solutions Pvt. Ltd.",
  "Core CarbonX Solutions Pvt. Ltd.",
  "Core CarbonX Solutions Pvt. Ltd.",
  "Multiple Proponents",
  "Multiple Proponents",
  "Indian Wind Power Association",
  "Multiple Proponents",
  "Multiple Proponents",
  "Hunan Yueyang Huabanqiao Market Development Co., Ltd.",
  "Pivot Bio, Inc.",
  "Sustainability Investment Promotion and Development Joint Stock Company",
  "Xiaogushan Hydropower Co., Ltd.",
  "Multiple Proponents",
  "PETROCHINA Changqing Oilfield Company",
  "Multiple Proponents",
  "Udzungwa Corridor Limited",
  "Jiangsu Lisaike Environmental Protection Material Technology Co., Ltd.",
  "Multiple Proponents",
  "Jiangxi Tianye Biotechnology Limited",
  "Multiple Proponents",
  "Multiple Proponents",
  "Hunan Yueyang Huabanqiao Market Development Co., Ltd.",
  "Hunan Yueyang Huabanqiao Market Development Co., Ltd.",
  "Hunan Yueyang Huabanqiao Market Development Co., Ltd.",
  "Soma Energy Co. Ltd",
  "Multiple Proponents",
  "Global Emissionairy, LLC",
  "ORIZON Valorização de Resíduos S.A.",
  "Hunan Yueyang Huabanqiao Market Development Co., Ltd.",
  "Hunan Yueyang Huabanqiao Market Development Co., Ltd.",
  "KANDHARI BEVERAGES PRIVATE LIMITED",
  "Hunan Yueyang Huabanqiao Market Development Co., Ltd.",
  "Deeprock Net Zero Technology (Shenzhen) Co., Ltd.",
  "Multiple Proponents",
  "Bugnavilla SAS",
  "Yangjiang Yangdong Huanyu New Energy Co., Ltd.",
  "Yangchun Huanchuang New Energy Co., Ltd.",
  "EcoPlanet Bamboo Group",
  "EcoPlanet Bamboo Group",
  "Canadian Rockies Hemp Corporation",
  "Caldere Elektrik Uretim A.S.",
  "NIDEPORT SOCIEDAD ANONIMA",
  "EcoPlanet Bamboo Group",
  "Cultivating Carbon Proprietary Limited",
  "R Enerji Elektrik Üretim A.Ş.",
  "Multiple Proponents",
  "Multiple Proponents",
  "Gangadhar Narsingdas Agrawal (HUF)",
  "Multiple Proponents",
  "Everbright Environmental Energy (Dongfang) Co., Ltd.",
  "SINOPEC GREEN ENERGY GEOTHERMAL SHANDONG DEVELOPMENT CO., LTD SHANGHE BRANCH",
  "Shaanxi Province Xixian new district Fengxi New Town Energy Development Co., Ltd.",
  "Tianjin Zhonglan Huanneng Co., Ltd",
  "Sagar Sugars and Allied Products Limited",
  "Man and Man Enterprise",
  "Ege Biyogaz Elektrik Üretim Anonim Şirketi",
  "Xinxiang Capital Environment Energy Co., Ltd.",
  "B.B. Energy (Asia) Pte Ltd",
  "AgriCapita Innotech Private Limited",
  "Multiple Proponents",
  "SB Agri Renewable Private Limited",
  "Nesa Power",
  "Kaona Power Supply Co., Ltd",
  "Vlinder Austria GmbH",
  "Department of Environment, Forest and Climate Change, Government of Uttar Pradesh",
  "Rasita Power Co. Ltd.",
  "Department of Environment, Forest and Climate Change, Government of Uttar Pradesh",
  "Jinyongli Carbon Oxygen Technology (Jilin) Co., Ltd.",
  "Future Carbon Consultoria e Projetos Florestais LTDA",
  "Lijin Huayu Breeding Swine Co., Ltd.",
  "Zhucheng Jintudi Organic Fertilizer Co., Ltd.",
  "Soil-Carbon Certification Services",
  "Thai Biogas Energy Company Ltd",
  "UniCarbo Energia e Biogás Ltda.",
  "Livelihoods Fund SICAV SIF",
  "Landmark Agri Exports Private Limited",
  "Rivulis Pte. Ltd.",
  "ECOBASE (PROJECT SPRUCE OÜ)",
  "Guangdong Zhanjiang Chia Tai Swine Industry Co., Ltd.",
  "Sulige Fuel Gas Power Generation Co., Ltd.",
  "Multiple Proponents",
  "Hangzhou Chunmeng Agricultural Technology Co., Ltd",
  "Hangzhou Chunmeng Agricultural Technology Co., Ltd",
  "Hangzhou Chunmeng Agricultural Technology Co., Ltd",
  "Hangzhou Chunmeng Agricultural Technology Co., Ltd",
  "Hangzhou Chunmeng Agricultural Technology Co., Ltd",
  "Shandong Xiantan Biotechnology Co Ltd",
  "Rajputana Biodiesel Private Limited",
  "Multiple Proponents",
  "Multiple Proponents",
  "Guangzhou Iceberg Environmental Consulting Services Co., Ltd.",
  "Burqin Jinhua Urban Construction and Investment Co.Ltd.",
  "Multiple Proponents",
  "Hangzhou Chunmeng Agricultural Technology Co., Ltd",
  "Chongqing Tongliang Zhonglanhuanneng Environmental Technology Co. Ltd.",
  "Xinzheng Yonder New Energy Co., Ltd.",
  "Department of Environment, Forest and Climate Change, Government of Uttar Pradesh",
  "DelAgua Health Rwanda (Voluntary) Limited.",
  "Ind Synergy Ltd",
  "Wind World (India) Ltd.",
  "Multiple Proponents",
  "VEDA CLIMATE CHANGE SOLUTIONS LTD",
  "UpEnergy Group",
  "Beijing Yonder Environmental Energy Technology Co., Ltd",
  "Bio Kütle Enerji Elektrik Üretim Sanayi ve Ticaret A.Ş.",
  "Bio Kütle Enerji Elektrik Üretim Sanayi ve Ticaret A.Ş.",
  "Shanghai Agro-Services Co., Ltd",
  "Shenzhen City Fuyuan Industry (Group) Co., Ltd.",
  "R.E.D.D. Carbon Credit Harvesting L.P",
  "Hubei Jiangling County Agricultural Technology Extension Center",
  "UAB Heavy Finance",
  "Anhui United Venture Capital Renewable Energy Technology Co.,Ltd.",
  "Sichuan Huixiang Ecological Agriculture Co., Ltd.",
  "Sichuan Huixiang Ecological Agriculture Co., Ltd.",
  "Sichuan Huixiang Ecological Agriculture Co., Ltd.",
  "Sichuan Huixiang Ecological Agriculture Co., Ltd.",
  "Indian Wind Power Association",
  "B.B. Energy (Asia) Pte Ltd",
  "Sichuan Huixiang Ecological Agriculture Co., Ltd.",
  "Hubei Jiangling County Agricultural Technology Extension Center",
  "Hubei Jiangling County Agricultural Technology Extension Center",
  "Hubei Jiangling County Agricultural Technology Extension Center",
  "Bosques Amazónicos",
  "Multiple Proponents",
  "Wuhan Haimingsen Environmental Protection Technology Co., Ltd.",
  "Wuhan Haimingsen Environmental Protection Technology Co., Ltd.",
  "K.C.P SUGAR AND INDUSTRIES CORPORATION LIMITED",
  "Worldview International Foundation",
  "Multiple Proponents",
  "Sichuan Huixiang Ecological Agriculture Co., Ltd.",
  "Wuhan Haimingsen Environmental Protection Technology Co., Ltd.",
  "Bosques Cautin S.A.",
  "Rivian Automotive, LLC.",
  "Dengkou County Yuantai Livestock Industry Co.Ltd.",
  "Harbin Beigen Technology Co., Ltd.",
  "Systemica (MYS E JLFL TREINAMENTO GERENCIAL LTDA)",
  "CARBON CREDITS CONSULTING S.R.L.",
  "K.C.P SUGAR AND INDUSTRIES CORPORATION LIMITED",
  "Mudurnu Enerji Sanayi ve Tcaret A.Ş.",
  "Smart Power for Rural Development India Foundation",
  "Re.Green Participações S.A.",
  "Enexor BioEnergy",
  "NaturAll Carbon Limited",
  "Wuhan Haimingsen Environmental Protection Technology Co., Ltd.",
  "Shenzhen Cypress Carbon Environmental Technology Service Co., Ltd",
  "Shenzhen Cypress Carbon Environmental Technology Service Co., Ltd",
  "Gupta Exim Private Limited",
  "ECOBASE (PROJECT SPRUCE OÜ)",
  "Shri Maa Marketing Private Limited",
  "Sichuan Huixiang Ecological Agriculture Co., Ltd.",
  "Wuhan Haimingsen Environmental Protection Technology Co., Ltd.",
  "Outreach Projects Pvt. Ltd.",
  "Outreach Projects Pvt. Ltd.",
  "Outreach Projects Pvt. Ltd.",
  "Outreach Projects Pvt. Ltd.",
  "Savita Oil Technologies Limited",
  "Outreach Projects Pvt. Ltd.",
  "Santo Stefano Srl",
  "Qingdao Gangya New Energy Co.,Ltd.",
  "Sichuan Huixiang Ecological Agriculture Co., Ltd.",
  "Gansu Founder Energy Conservation Sci&Tech Service Co., Ltd.",
  "MicroEnergy Credits Corp",
  "Indian Wind Power Association",
  "Multiple Proponents",
  "Wuhan Haimingsen Environmental Protection Technology Co., Ltd.",
  "Yueyang Agricultural and Rural Development Group Co., Ltd",
  "National Forestry and Grassland Administration Northwest Investigation and Planning Institute",
  "Yueyang Agricultural and Rural Development Group Co., Ltd",
  "Yueyang Agricultural and Rural Development Group Co., Ltd",
  "Jilin CGN Wind Power Co. Ltd.",
  "Yueyang Agricultural and Rural Development Group Co., Ltd",
  "Yueyang Agricultural and Rural Development Group Co., Ltd",
  "Yueyang Agricultural and Rural Development Group Co., Ltd",
  "Yueyang Agricultural and Rural Development Group Co., Ltd",
  "Yueyang Agricultural and Rural Development Group Co., Ltd",
  "Yueyang Agricultural and Rural Development Group Co., Ltd",
  "Climit",
  "SilviCarbon Lao Sole Company Ltd.",
  "INNOVENT COMORES S.A",
  "Carbon Friendly Pty Ltd",
  "Vandana Ispat Limited",
  "Sichuan Dingfeng State-owned Assets Investment (Group) Co. LTD",
  "Sichuan Huixiang Ecological Agriculture Co., Ltd.",
  "Chongqing Tanray Technology Co.,Ltd.",
  "Chongqing Tanray Technology Co.,Ltd.",
  "Sichuan Huixiang Ecological Agriculture Co., Ltd.",
  "ECOBASE (PROJECT SPRUCE OÜ)",
  "Biosun Ödemis Kati Atik İsleme Enerji ve Cevre San. Tic. A.S",
  "Nesher Cement Enterprises Ltd.",
  "Wind World (India) Ltd.",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "Wind World (India) Ltd.",
  "Carbon Green Investments Guernsey Limited",
  "Perimeter Forest Limited Partnership",
  "Multiple Proponents",
  "Forest Carbon Works PBC",
  "CENTRAFOREST",
  "Multiple Proponents",
  "Worldview International Foundation",
  "Varaha ClimateAg Private Limited",
  "Lalan Rubbers (Pvt) Ltd",
  "Green Impact Limited Company",
  "Brightspark Energy Pvt. Ltd.",
  "Partanna Global",
  "Sichuan Huixiang Ecological Agriculture Co., Ltd.",
  "Wuhan Haimingsen Environmental Protection Technology Co., Ltd.",
  "Körfez Enerji Sanayi ve Ticaret Anonim Şirketi",
  "Wuhan Haimingsen Environmental Protection Technology Co., Ltd.",
  "Multiple Proponents",
  "Jiyuan Weiheng Cow Breeding Co., Ltd.",
  "Multiple Proponents",
  "Multiple Proponents",
  "DelAgua Health Rwanda (Voluntary) Limited.",
  "Sustainability Investment Promotion and Development Joint Stock Company",
  "Sustainability Investment Promotion and Development Joint Stock Company",
  "DelAgua Health Rwanda (Voluntary) Limited.",
  "Enercon (India) Limited",
  "Wuhan Haimingsen Environmental Protection Technology Co., Ltd.",
  "Multiple Proponents",
  "Lingguan State-Owned Forest Protection Bureau of Yuexi County",
  "Multiple Proponents",
  "Multiple Proponents",
  "AGROREFORESTADORA RANCHO VICTORIA S.A",
  "Hidroluz Centrais Elétricas Ltda",
  "Brightspark Energy Pvt. Ltd.",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "Chongqing Gengfang Agricultural Development Co., Ltd.",
  "Chongqing Gengfang Agricultural Development Co., Ltd.",
  "Chongqing Gengfang Agricultural Development Co., Ltd.",
  "Chongqing Gengfang Agricultural Development Co., Ltd.",
  "Chongqing Gengfang Agricultural Development Co., Ltd.",
  "Chongqing Gengfang Agricultural Development Co., Ltd.",
  "Chongqing Gengfang Agricultural Development Co., Ltd.",
  "Chongqing Gengfang Agricultural Development Co., Ltd.",
  "Prestadora de Servicios Públicos La Cascada SA ESP",
  "Chongqing Gengfang Agricultural Development Co., Ltd.",
  "Shenzhen PhasCon Technologies Co., Ltd.",
  "Chongqing Gengfang Agricultural Development Co., Ltd.",
  "Chongqing Gengfang Agricultural Development Co., Ltd.",
  "Chongqing Gengfang Agricultural Development Co., Ltd.",
  "Chongqing Gengfang Agricultural Development Co., Ltd.",
  "Heyuan Zhengbo New Energy Development Co., Ltd.",
  "Multiple Proponents",
  "Fangchenggang Xinggang Agricultural Development Co., Ltd",
  "Henan Yangxiang Breeding Co., Ltd",
  "Rain Calcining Limited",
  "Henan Yangxiang Breeding Co., Ltd",
  "Henan Yangxiang Breeding Co., Ltd",
  "Henan Yangxiang Breeding Co., Ltd",
  "Henan Yangxiang Breeding Co., Ltd",
  "Climate Detox Private Limited",
  "Huaiji County Weizhou Construction Investment and Development Group Co., Ltd",
  "Mid American Growers",
  "Henan Yangxiang Breeding Co., Ltd",
  "Wuhan Haimingsen Environmental Protection Technology Co., Ltd.",
  "Sichuan Huixiang Ecological Agriculture Co., Ltd.",
  "Multiple Proponents",
  "Multiple Proponents",
  "Multiple Proponents",
  "World Institute of Sustainable Energy",
  "Multiple Proponents",
  "Multiple Proponents",
  "Multiple Proponents",
  "Jinsha Yonder Southwest New Energy Development Co., Ltd.",
  "Barutçuoğlu İnşaat Sanayi ve Ticaret Limited Şirketi",
  "Offis Textile Ltd.",
  "Sterlite Industries (India) Limited",
  "Anhua Yuanjing Environmental Protection Technology Co. LtD.",
  "Rivulis Pte. Ltd.",
  "C-Quest Capital SG India LED Private Limited",
  "C-Quest Capital SG India LED Private Limited",
  "C-Quest Capital SG India LED Private Limited",
  "C-Quest Capital SG India LED Private Limited",
  "Multiple Proponents",
  "Multiple Proponents",
  "Multiple Proponents",
  "Varaha ClimateAg Private Limited",
  "Multiple Proponents",
  "Varaha ClimateAg Private Limited",
  "Multiple Proponents",
  "Mengjin Branch of Henan Qingtian New Energy Co., Ltd.",
  "Sequoia Plantation Togo SAU",
  "Carbon Limit",
  "Gansu Datang Yumen Wind Power Co., Ltd.",
  "TOHMA Biyogaz Enerji Limited Şirketi",
  "Jiangxi Huapeng Low-carbon Technology Limited",
  "Ísorka ehf",
  "Sichuan Huixiang Ecological Agriculture Co., Ltd.",
  "Qingdao Huawei Wind Power Co. Ltd",
  "Zhejiang Zhongzheng Forestry Development Co.,Ltd",
  "Sichuan Huixiang Ecological Agriculture Co., Ltd.",
  "CARBON CREDITS CONSULTING S.R.L.",
  "CARBON CREDITS CONSULTING S.R.L.",
  "Multiple Proponents",
  "Climanext Private Limited",
  "HECI Longyuan Chongli Windpower Co., Ltd",
  "Lianzhou Dongkang Renewable Energy Technology Co., Ltd.",
  "C-Quest Capital CR Stoves Pte. Ltd.",
  "Climanext Private Limited",
  "Henan Yangxiang Breeding Co., Ltd",
  "Henan Yangxiang Breeding Co., Ltd",
  "Sichuan Cangxi Urban Investment Carbon Sink Technology Co., Ltd.",
  "Sichuan Huixiang Ecological Agriculture Co., Ltd.",
  "Global Carbon Solutions",
  "Chifeng Xinsheng Wind Power Co., Ltd.",
  "Hui County XINGJIANG Muge Breeding Co., LTD.",
  "Changde Dingcheng District Xingjiang Muge Breeding Co., Ltd.",
  "Guangzhou Qichen Technology Co., Ltd.",
  "ECONEXUS Carbon Credit Co., Ltd.,",
  "Sustainability Investment Promotion and Development Joint Stock Company",
  "Sustainability Investment Promotion and Development Joint Stock Company",
  "Sinochem Agriculture Holdings",
  "Jiangsu Yongyin Chemical Fibre Co., Ltd.",
  "Longyuan (Bayannaoer) Wind Power Co., Ltd.",
  "INNER MONGOLIA DAXING’ANLING CARBON SINK TECHNOLOGY CO.LTD",
  "Shri Maa Marketing Private Limited",
  "FMH Enerji Ticaret Limited Şirketi",
  "Società Agricola Blue Valley Srl",
  "Zhejiang Jia’ao Green New Energy Co., Ltd.",
  "Daming Jiujin Energy Co., Ltd",
  "Dodhia Synthetics Limited",
  "Multiple Proponents",
  "Jiangxi Green Energy Agricultural Development Co., Ltd",
  "Jiangxi Green Energy Agricultural Development Co., Ltd",
  "POWA International",
  "Chongqing Tanray Technology Co.,Ltd.",
  "Chongqing Tanray Technology Co.,Ltd.",
  "Huanan Longyuan Wind Power Co., Ltd.",
  "Aksu Xingjiang Muge FOOD Co., Ltd.",
  "Golmud Xingjiang MUGE FOOD Co., Ltd",
  "EKI Energy Services Limited",
  "Hebei Zhonghai Huaneng Energy Co., Ltd",
  "MAUTO HOLDCO",
  "Huanan Longyuan Wind Power Co., Ltd.",
  "INNER MONGOLIA DAXING’ANLING CARBON SINK TECHNOLOGY CO.LTD",
  "Sichuan Huashun Haitian Chemical Fiber Co., Ltd.",
  "Carbon Group Agro-Climatic Solutions LLC",
  "Hubei Jinnonggu Agriculture and Animal Husbandry Technology Co., Ltd",
  "KORFEZ ENERJI SAN. VE TIC. A.S.",
  "Livelihoods Fund SICAV SIF",
  "Agcert do Brasil Solucoes Ambientais Ltda.",
  "Yichun Xinganling Wind Power Co., Ltd.",
  "Zhejiang Southeast Electric Power Co., Ltd.",
  "DelAgua Health Rwanda (Voluntary) Limited.",
  "Multiple Proponents",
  "Multiple Proponents",
  "INOCAS",
  "Fideicomiso Financiero Forestal Terraligna",
  "Roi Et Flour Company Limited",
  "Qinghai Taiheyuan Agriculture Technology Co., Ltd",
  "Biokütle Enerji Elektrik Üretim Anonim Şirketi",
  "Circular Impact, Inc.",
  "Systemica (MYS E JLFL TREINAMENTO GERENCIAL LTDA)",
  "CarbonCure Technologies Inc.",
  "Veolia Environmental Services Energy Recovery Ltd",
  "Multiple Proponents",
  "Agreena ApS",
  "CSG EV LLC",
  "SCG Cement Co., Ltd.",
  "GEP (Myanmar) Co., Ltd. (Head Office)",
  "ITC Limited",
  "Multiple Proponents",
  "Emergent Ventures India Private Limited",
  "Green Sustainable Ventures (Cambodia) Co., Ltd.",
  "Wind World (India) Ltd.",
  "BRCARBON SERVIÇOS AMBIENTAIS LTDA",
  "Sino-Carbon Carbon Sink Science and Technology Development(Shangqiu) Co., LTD",
  "Banpong Tapioca Flour Industrial Co., Ltd.",
  "Jiangxi Zhenghe Ecological Agriculture Co., Ltd.",
  "Xuwen Xinpenggang Building Materials Co., Ltd.",
  "d.light design, Ltd",
  "Timing Carbon Asset Management Co., Ltd.",
  "EKI Energy Services Limited",
  "EKI Energy Services Limited",
  "Hi – Tech Carbon",
  "EKI Energy Services Limited",
  "GAIA s.r.l. Società Agricola",
  "Varaha ClimateAg Private Limited",
  "Nehe Agriculture and Animal Husbandry Technology Development Co., Ltd.",
  "Future Carbon Holding S.A.",
  "Future Carbon Holding S.A.",
  "Chakangrao Starch Co. Ltd",
  "Guangdong Xinsheng Environmental Protection Technology Co., Ltd.",
  "CARBON CREDITS CONSULTING S.R.L.",
  "Genhe Forest Industry Co. LTD",
  "Citizen:Forest:Invest Inc",
  "Kluthe Sustainability Management, Ltd",
  "Miramichi Timberlands Ltd",
  "Deeg Timberland LTD",
  "Multiple Proponents",
  "Lamaja Forestry Holding Ltd",
  "Thanawat Biogas Co., Ltd",
  "Kuduer Forest Industry Co., LTD",
  "Future Carbon Holding S.A.",
  "Future Carbon Holding S.A.",
  "Xuwen Xinpenggang Building Materials Co., Ltd.",
  "Xuwen Xinpenggang Building Materials Co., Ltd.",
  "Future Carbon Holding S.A.",
  "PT. Medco LPG Kaji (MLK)",
  "Aurum Sustainable Forest Inc.",
  "Future Carbon Holding S.A.",
  "Future Carbon Holding S.A.",
  "Zhejiang Shengtang Environmental Protection Technology Co., Ltd.",
  "Xuwen Xinpenggang Building Materials Co., Ltd.",
  "Thai Biogas Energy Company Ltd",
  "Multiple Proponents",
  "Xuwen Xinpenggang Building Materials Co., Ltd.",
  "Xuwen Xinpenggang Building Materials Co., Ltd.",
  "Damao Banner Eco-Construction Investment Co., Ltd.",
  "Synopex Inc.",
  "Xuwen Xinpenggang Building Materials Co., Ltd.",
  "Multiple Proponents",
  "Longnan State Capital Investment Management Company Limited",
  "TFS Akaryakıt Hizmetleri A.Ş.",
  "Chorchaiwat Industry Co., Ltd.",
  "Zhejiang Zhongzheng Forestry Development Co.,Ltd",
  "Shandong Yiji Ecological Agriculture Technology Co., Ltd.",
  "Shandong Yiji Ecological Agriculture Technology Co., Ltd.",
  "Shandong Yiji Ecological Agriculture Technology Co., Ltd.",
  "Heilongjiang Tanlv Ecological Resources Development Co., Ltd.",
  "ECCON Soluções Ambientais",
  "Carbon Friendly Pty Ltd",
  "Wind World (India) Ltd.",
  "National Forestry and Grassland Administration Northwest Investigation and Planning Institute",
  "National Forestry and Grassland Administration Northwest Investigation and Planning Institute",
  "Multiple Proponents",
  "Multiple Proponents",
  "Multiple Proponents",
  "Multiple Proponents",
  "C-Quest Capital Stoves Asia Limited",
  "Hebei Construction Investment Yuzhou Wind Energy Co., Ltd.",
  "Multiple Proponents",
  "Future Carbon Holding S.A.",
  "Multiple Proponents",
  "RECICLE CATARINENSE DE RESÍDUOS LTDA.",
  "Multiple Proponents",
  "Hebei Construction Investment Zhongxing Wind Energy CO., LTD",
  "Future Carbon Holding S.A.",
  "Future Carbon Holding S.A.",
  "Future Carbon Holding S.A.",
  "CO2Logic SA",
  "Department of Environment, Forest and Climate Change, Government of Uttar Pradesh",
  "UAB Heavy Finance",
  "Multiple Proponents",
  "DelAgua Health Rwanda (Voluntary) Limited.",
  "BioLite",
  "C-Quest Capital CR Stoves Pte. Ltd.",
  "C-Quest Capital CR Stoves Pte. Ltd.",
  "C-Quest Capital CR Stoves Pte. Ltd.",
  "SD Biosupply Co. Ltd.",
  "C-Quest Capital CR Stoves Pte. Ltd.",
  "C-Quest Capital CR Stoves Pte. Ltd.",
  "C-Quest Capital CR Stoves Pte. Ltd.",
  "Otog Front Banner Cultural Tourism Group Ltd.",
  "Multiple Proponents",
  "Multiple Proponents",
  "C-Quest Capital Malaysia Global Stoves Limited",
  "Guizhou Aonong Qihuan Animal Husbandry Co., Ltd",
  "Guizhou Aonong Qihuan Animal Husbandry Co., Ltd",
  "Wuwei everbright Environmental Energy Co., Ltd.",
  "P.S.C. Starch Products Public Company Limited",
  "Anhui Enyang Ecological Agriculture Technology Co., Ltd",
  "Anhui Enyang Ecological Agriculture Technology Co., Ltd",
  "Anhui Enyang Ecological Agriculture Technology Co., Ltd",
  "Multiple Proponents",
  "Multiple Proponents",
  "Heshan Lvhu (Green Lake) bioenergy CO., Ltd Company",
  "Yilan Longyuan Wind Power Co., Ltd.",
  "Shimen County Economic Construction Investment Development Co., Ltd",
  "Multiple Proponents",
  "Multiple Proponents",
  "Greenam Energy Private Limited",
  "EKI Energy Services Limited",
  "Akenerji Elektrik Uretim A.S",
  "Huizhi (Shanghai) Agricultural Technology Co., Ltd",
  "Multiple Proponents",
  "Yushu Rongxing City Development Co., Ltd",
  "Hubei Aonong Animal Husbandry Development Co., Ltd",
  "Multiple Proponents",
  "Yongtai Golden Egg Development Co., Ltd",
  "Nong Bua Farm & Country Home Village Co., Ltd.",
  "Multiple Proponents",
  "Taian Jinliyuan Fertilizer Technology Co., Ltd.",
  "Yılmazlar Madencilik Sanayi ve Ticaret Limited Şirketi",
  "Kütahya Biyokütle Enerji Üretim Sanayi ve Ticaret Anonim Şirketi",
  "GNR FORTALEZA VALORIZAÇÃO DE BIOGÁS LTDA",
  "Longyuan Qidong Wind Power Co., Ltd.",
  "Henan BCCY Environmental Energy CO.,LTD.",
  "C-Quest Capital Malaysia Global Stoves Limited",
  "Beijing Qianyuhui Environmental Technology Co., Ltd.",
  "Carbonibus S.r.l.",
  "Zhejiang Wenling Donghaitang Wind Power Co.,",
  "Multiple Proponents",
  "Multiple Proponents",
  "d.light design, Ltd",
  "d.light design, Ltd",
  "d.light design, Ltd",
  "ReNew Power Synergy Private Limited",
  "Multiple Proponents",
  "Multiple Proponents",
  "Changzhi Suburb Baoneng New Energy Development Co., Ltd",
  "Harbin Ruying Technology Co., Ltd.",
  "Future Carbon Holding S.A.",
  "Multiple Proponents",
  "Qinghai Tanzhihuilin Co., LTD",
  "Multiple Proponents",
  "EKI Energy Services Limited",
  "Beijing Qianyuhui Environmental Technology Co., Ltd.",
  "Multiple Proponents",
  "Shaoyang County Haishang Ecological Agriculture Technology Co., Ltd.",
  "Ordu Yenilenebilir Enerji Elektrik Üretim Sanayi ve Ticaret Anonim Şirketi",
  "Sahamitr Tapioca Chonburi",
  "GHE Impact Ventures Private Limited",
  "Multiple Proponents",
  "Green Wave Africa Pty Ltd",
  "Qinghai Tanzhihuilin Co., LTD",
  "Zhonghuan Xinwei Green Technology (Lin Zhang) Co., Ltd.",
  "The Natural Palm Oil (Chumporn) Co., Ltd.",
  "Multiple Proponents",
  "SHARJAH WASTE TO ENERGY COMPANY.LLC",
  "Future Carbon Holding S.A.",
  "Qinghai Tanzhihuilin Co., LTD",
  "Amazônia Agroflorestal Comercialização de Produtos Agroflorestais Ltda",
  "Kean Charoen Co., Ltd.",
  "Lingguan State-Owned Forest Protection Bureau of Yuexi County",
  "Monsoon Carbon Pte Ltd",
  "S.C. Industry Co., Ltd.",
  "Heilongjiang Tanlv Ecological Resources Development Co., Ltd.",
  "Sichuan Cangxi Urban Investment Carbon Sink Technology Co., Ltd.",
  "Brascarbon Consultoria Projetos e Representação S/A",
  "Brascarbon Consultoria Projetos e Representação S/A",
  "Multiple Proponents",
  "Gaotang County Finance Investment Co.,Ltd.",
  "Harbin Ruying Technology Co., Ltd.",
  "Yongji Huaxinda Clean Energy Co., Ltd",
  "Community Markets for Conservation (COMACO)",
  "Greenply Industries Limited (GIL)",
  "Chol Charoen Co., Ltd.",
  "Kluthe Sustainability Management, Ltd",
  "Harbin Ruying Technology Co., Ltd.",
  "C-Quest Capital SG Angola Stoves Private Limited",
  "C-Quest Capital Stoves Asia Limited",
  "Multiple Proponents",
  "C-Quest Capital SG India Holdings Private Limited",
  "C-Quest Capital SG India Holdings Private Limited",
  "C-Quest Capital BD Stoves 1 Pte. Ltd.",
  "C-Quest Capital SGT Asia Stoves Private Limited",
  "C-Quest Capital SGT Asia Stoves Private Limited",
  "C-Quest Capital SGT Asia Stoves Private Limited",
  "C-Quest Capital SGT Asia Stoves Private Limited",
  "Zhejiang Southeast Electric Power Co., Ltd.",
  "Beijing AsiaInfo Big Data,Inc.",
  "C-Quest Capital Stoves Asia Limited",
  "Sudokwon Landfill Site Management Co.",
  "Multiple Proponents",
  "Multiple Proponents",
  "National Forestry and Grassland Administration Northwest Investigation and Planning Institute",
  "Zhejiang Green Intelligent Link Co., Ltd.",
  "Yongchang County Minrong Hydropower Development Co",
  "Hunan Anfu Environmental Protection Technology Co.,Ltd",
  "Yueyang Weiteng New Energy Co., Ltd.",
  "Gansu Chaijiaxia Hydropower Co., Ltd.",
  "Muyuan Foods Co., Ltd.",
  "Muyuan Foods Co., Ltd.",
  "Multiple Proponents",
  "Muyuan Foods Co., Ltd.",
  "Multiple Proponents",
  "Muyuan Foods Co., Ltd.",
  "RADE WIND ENERGY PRIVATE LIMITED",
  "Muyuan Foods Co., Ltd.",
  "Muyuan Foods Co., Ltd.",
  "Muyuan Foods Co., Ltd.",
  "Muyuan Foods Co., Ltd.",
  "Muyuan Foods Co., Ltd.",
  "Gautam Freight Pvt. Ltd (GFPL)",
  "Sequoia Plantation Benin SAU",
  "Shaoyang County Haishang Ecological Agriculture Technology Co., Ltd.",
  "Multiple Proponents",
  "Yingpeng Chemical Co., Ltd.",
  "Fujian Huanrong Environmental Protection Co., Ltd",
  "Thai Biogas Energy Company Ltd",
  "Giant Eagle, Inc.",
  "Multiple Proponents",
  "Sussex County Municipal Utilities Authority",
  "Shakumbhri Straw Products Limited",
  "Tongren Tianshengqiao Electricity Generation Co",
  "Talawakelle Tea Estates PLC",
  "SPM Feedmill Co., Ltd.",
  "Multiple Proponents",
  "J.R. Simplot Company",
  "P.V.D. International Co., Ltd.",
  "Chongqing Wujiang Industry (power) Group",
  "Maryland Environmental Services",
  "Rio do Sangue Energia S.A.",
  "VCF Group Company Ltd.",
  "Gansu Diantou Bingling Hydropower Development Co.",
  "KHATAU NARBHERAM & CO.",
  "Multiple Proponents",
  "Multiple Proponents",
  "Cassava Waste To Energy Co.,Ltd.",
  "PTC India Ltd.",
  "Hubei Jiugongshan Wind Power Co.,Ltd.",
  "Sichuan Mabian Xianjiapuhe Power Generation Co., L",
  "Gansu Mingzhu Hydropower Development Co., Ltd.",
  "VCF Group Company Ltd.",
  "Sinohydro Renewable Energy Co., Ltd.",
  "Westport Innovations",
  "Rama Paper Mills Limtied",
  "Bajaj Hindusthan Ltd",
  "Dallas Clean Energy LLC",
  "Kean Charoen Co., Ltd.",
  "NextEra Energy Resources",
  "DELHI METRO RAILCORPORATION",
  "Shandong Shifang New Energy Co., Ltd.",
  "Xinjiang Tianfeng Wind Power Co., Ltd.",
  "The CECIC Wind Power (Xinjiang) Co. Ltd.",
  "The CECIC Wind Power (Xinjiang) Co. Ltd.",
  "Zhongdiantou Nanyang Fangcheng Wind Power Co. Ltd",
  "Shanxi Zhangze Electric Power Co., Ltd",
  "Yichun Longyuan Hero Asia Wind Power Co., Ltd",
  "Jintai Hydropower Co. Ltd in Gansu Province",
  "Enercon (India) Limited",
  "Tata Teleservices Maharashtra Limited",
  "Green Gas Colombia",
  "Carbon Resource Management Ltd",
  "HINDALCO INDUSTRIES LIMITED",
  "ACC Limited",
  "Raj Infrastructure Development (India) Pvt. Ltd.",
  "DSL HYDROWATT PRIVATE LIMITED",
  "The Rai Saheb Rekhchand Mohota Spinning & Weaving",
  "Zhejiang Longyou Xiaoxitan Hydro Complex Developme",
  "PT. PLN (Persero)",
  "PT. PLN (Persero)",
  "PT. PLN (Persero)",
  "Wind World (India) Ltd.",
  "CASC Longyuan (Benxi) Wind Power Co., Ltd.",
  "Enercon (India) Limited",
  "Bionersis S.A.",
  "Muthoot Finance Limited",
  "Harisons Hydel Construction Co. (P) Limited",
  "Zhangjiagang Huaxing Power Co., Ltd.",
  "Multiple Proponents",
  "Multiple Proponents",
  "Multiple Proponents",
  "National Enterprises",
  "Multiple Proponents",
  "Greater Lebanon Refuse Authority",
  "Willow Creek",
  "Multiple Proponents",
  "Multiple Proponents",
  "ITC Limited - Paperboards & Specialty Papers Divis",
  "Zhengzhou Combined Cycle Power Co., Ltd",
  "Hainan Longyuan Wind Power Co. Ltd.",
  "Shenyang Longyuan Wind Power Co., Ltd.",
  "Bilgin Güç Santralleri Enerji Üretim A.Ş.",
  "Shenyang Longyuan Wind Power Co., Ltd.",
  "Pioneer Power Limited",
  "GTN ENTERPRISES LIMITED",
  "ACCIONA WIND ENERGY PVT. LTD.",
  "Wind World (India) Ltd.",
  "Shanghai Yangtze Delta Investment Consulting Co.",
  "La Opala RG Limited",
  "Consórcio Empresarial Salto Pilão – CESAP",
  "PICA DE HULE NATURAL, S.A.",
  "Kanaka Management Services Private Limited",
  "Chamoli Hydro Power Pvt. Ltd",
  "Shandong Changdao Wind Power Co., Ltd",
  "Fujian Putian Nanri Houshanzai Wind Power Generati",
  "Yichun Xinganling Wind Power Co., Ltd.",
  "Multiple Proponents",
  "Enercon (India) Limited",
  "Pioneer Power Corporation Limited",
  "Hidromaule S.A",
  "Godawari Power and Ispat Ltd.",
  "UPM S.A",
  "Multiple Proponents",
  "Wilmar International Limited",
  "Wilmar International Limited",
  "Wilmar International Limited",
  "Wilmar International Limited",
  "Ceramica Milenium",
  "Henan Zhongyuan Gas Power Company Ltd.",
  "Chevron Hydel (Pvt.) Ltd.",
  "Gensol Consultants Pvt. Ltd.",
  "Akenerji Elektrik Uretim A.S",
  "Akenerji Elektrik Uretim A.S",
  "Multiple Proponents",
  "Akenerji Elektrik Uretim A.S",
  "CLP India Private Limited",
  "ITC Limited",
  "Shenyang Longyuan Hero Asia Wind Power Co.,Ltd.",
  "Multiple Proponents",
  "CGN Wind Power CO.,Ltd.",
  "Colbun S.A.",
  "RESURGE ENERGY PRIVATE LIMITED",
  "CGN Wind Power CO.,Ltd.",
  "CGN Wind Power CO.,Ltd.",
  "RESURGE ENERGY PRIVATE LIMITED",
  "Jilin CGN Wind Power Co. Ltd.",
  "RESURGE ENERGY PRIVATE LIMITED",
  "Jilin CGN Wind Power Co. Ltd.",
  "KAMARHATTY POWER LIMITED",
  "Cerâmica Lucevans Ltda. – ME.",
  "Rajaram maize Products",
  "Suma Shilp Limited",
  "Xishuangbanna Silver Sand Electric Power Co.,Ltd",
  "DENIZ Elektrik Uretim Ltd",
  "OZGUR ELEKTRIK URETIM A.S.",
  "MSPL Limited",
  "Xilinguole Guotai Wind Power Co., Ltd.",
  "Paschim Hydro Energy Pvt. Ltd.",
  "Ceramica Ituiutaba LTDA - ME",
  "Vessels Coal Gas Inc.",
  "Xinjiang Tianfu Thermoelectric Co., Ltd.",
  "Emirates CMS Power Company",
  "Ecofren Power & Projects Ltd",
  "Wildlife Works Carbon LLC",
  "Sterling Agro Industries Ltd.",
  "Ansal Properties & Infrastructure Limited",
  "DEGIRMENUSTU ENERJI URETIM TICARET VE SANAYI AS",
  "Deepak Fertilisers and Petrochemical Corp. Ltd.",
  "Rashtriya Chemicals and Fertilizers (RCF)",
  "C. Mahendra Exports Limited",
  "Honeywell International Inc.",
  "Arkhangelsk Pulp and Paper Mill",
  "China Resources Wind Power (Shantouchaonan) Co., L",
  "EOLICSA (Eolica San Cristobal S.A.)",
  "Dalian PhasCon Technologies Co., Ltd.",
  "Keyrock Energy, LLC",
  "China Resources Wind Power (Shantou Chaonan) Co., Ltd.",
  "Shanghai BASF Polyurethane Co., Ltd.",
  "Asorpar Ltd.",
  "Multiple Proponents",
  "Enerjisa Enerji Uretim A.S.",
  "Auro Mira Bio Energy Madurai Limited",
  "Panzhihua Reshuihe Electric Power Development Co.",
  "Hoang Son Construction Company Limited",
  "Texonic Instruments",
  "Elestas Elektrik Uretim A.S.",
  "Elestas Elektrik Uretim A.S.",
  "Hanuman Agro Industries Ltd.",
  "JAROENSOMPONG CO., LTD.",
  "Multiple Proponents",
  "Peter Downie",
  "Yantai Dongyuan Group Laizhou Wind Power Co., Ltd",
  "Wilmar International Limited",
  "Kangwal Polyester Co.,Ltd",
  "Siddeshwari Industries Pvt Ltd",
  "Tokyo Cement Company (Lanka) Ltd.",
  "East India Commercial Co. Ltd.",
  "M/s Priyadarshini Polysacks Ltd.",
  "Clean Air Action Corporation",
  "Clean Air Action Corporation",
  "Clean Air Action Corporation",
  "Clean Air Action Corporation",
  "Tektuð Elektrik Üretim A.Þ.",
  "Enercon (India) Limited",
  "Hong Kong Wind Power (Muling) Ltd.",
  "Multiple Proponents",
  "Yeong Yang Wind Power Corporation",
  "R.PRAVEEN CHANDRA",
  "Multiple Proponents",
  "Beyobasi Enerji Uretimi A.S.",
  "Him Urja Private Limited",
  "Multiple Proponents",
  "Bionersis S.A.",
  "Nature Conservancy of Canada",
  "Gujarat Alkalies and Chemicals Limited",
  "Bayannaoer Wulatezhongqi Fuhui Wind Power Co., Ltd",
  "Multiple Proponents",
  "Cauvery Hydro Energy Limited",
  "KRBL LIMITED",
  "Wildlife Works Carbon LLC",
  "Camco International Group, Inc.",
  "Satia Industries Limited",
  "Gensol Consultants Pvt. Ltd.",
  "Shenyang Laohuchong Municipal Solid Waste Manageme",
  "M/s Paranjape Autocast Pvt. Ltd.",
  "Magnesium Products of America (also denoted as Meridian Magnesium Products of America or MPA)",
  "MINERAL ENTERPRISES LIMITED",
  "Longchuan Minhong Hydro Power Co., Ltd.",
  "Lanco Kondapalli Power Private Limited",
  "M/s. Ambika Solvex Ltd.",
  "Multiple Proponents",
  "Walden Properties Pvt. Ltd.",
  "Sukhbir Agro Energy Limited",
  "RESURGE ENERGY PRIVATE LIMITED",
  "Pioneer Power Corporation Limited",
  "National Bio Energy Co., Ltd",
  "RESURGE ENERGY PRIVATE LIMITED",
  "The Korea Energy Foundation (KEF)",
  "Multiple Proponents",
  "Loyal Textiles Mills Ltd.",
  "Ratnamani Metals & Tubes Limited",
  "J.R. Dairy",
  "TCS Textiles Pvt LTD",
  "Bos Dairy Farm Project",
  "Viet Nguyen Construction Works Joint Stock Company",
  "JCT Ltd",
  "SURANA INDUSTRIES LTD",
  "County of Rockingham",
  "Rajasthan State Mines & Minerals Ltd",
  "Multiple Proponents",
  "Flathead Electric CooperativeEner",
  "Multiple Proponents",
  "West Star North Dairy",
  "M/s Mula Sahakari Sakhar Karkhana Ltd.",
  "LAKSHMI MACHINE WORKS LTD",
  "Korea Rural Community Corporation",
  "Grütter Consulting AG",
  "Boden Creek Ecological Preserve",
  "Oso Oil and Gas Properties, LLC",
  "Shanghai Chuanji Investment Management Co., Ltd",
  "Multiple Proponents",
  "Universal Biomass Energy Private Limited",
  "Lahore Compost (Private) Limited",
  "Wilmar International Limited",
  "Gansu Zhangye Dagushan Hydropower Co. Ltd.",
  "Koganti Power Limited",
  "Belgaum Wind Farms Private Limited",
  "Guizhou Wujiang Qingshuihe Hydropower Development Co., Ltd",
  "Ceykar Elektrik Üretim A.S.",
  "Bosques Amazónicos",
  "Shenyang Longyuan Wind Power Co., Ltd.",
  "Deniz Elektrik Uretim Ltd. Sti.",
  "Shenyang Longyuan Wind Power Co., Ltd.",
  "Sanwaria Agro Oils Limited",
  "WMI Power Private Limited",
  "Sree Iswarya Textiles Pvt Ltd",
  "Guodian Xinjiang Alashankou Wind Power Development",
  "Multiple Proponents",
  "Zhangye Longhui Hydropower Co. Ltd.",
  "Wuxi Longshui Development Co. Ltd",
  "RHG Enerturk Enerji Uretim ve Tic. A.S.",
  "Multiple Proponents",
  "SPM Feedmill Co., Ltd.",
  "Hunan Xinshao Xiaoxi Hydropower Development Co Ltd",
  "Multiple Proponents",
  "Face the Future",
  "Greenchoice",
  "InfiniteEARTH",
  "GHCL Limited",
  "Simran Wind Project Private Limited",
  "Gujarat Narmada Valley Fertilizers Company Ltd.",
  "Multiple Proponents",
  "Multiple Proponents",
  "Lanco Tanjore Power Company Limited",
  "Multiple Proponents",
  "Enercon (India) Limited",
  "Windage Power Company (P) LTD",
  "Coway International Techtrans Co., Ltd.",
  "MP Orange, LLC",
  "Cakit Enerji A.S.",
  "FC Landfill Energy LLC",
  "SAIS José Carlos Mariategui",
  "Star Energy Geothermal (Wayang Windu) Limited",
  "Prakash Industries Limited",
  "Patspin India Ltd",
  "C-Quest Capital",
  "C-Quest Capital",
  "REI Agro Limited",
  "Tektuð Elektrik Üretim A.Þ.",
  "Sheth Developers Pvt. Ltd.",
  "City of Johannesburg: Transportation Department",
  "TANGEDCO LIMITED",
  "Hebei Construction Investment New Energy Co., Ltd.",
  "REDAS Elektrik Uretim Dagitim Pazarlama Sanayi",
  "TAOHUAJIANG ENERGY DEVELOPMENT CO., LTD",
  "Asia Wind Power (Mudanjiang) Ltd.",
  "EcoSecurities International Limited",
  "POWERICA LIMITED",
  "POWERICA LIMITED",
  "Multiple Proponents",
  "Energias Industriales S.A.",
  "Bajaj Hindusthan Ltd",
  "Huaneng Zhongdian Weihai Wind Power Co",
  "M/s. NSL Renewable Power Private Limited",
  "Kunming Huan Ye Project Development Co. Ltd.",
  "Multiple Proponents",
  "VEC Energy Limited",
  "Hebei Hong-Song Wind Power Share Holding Ltd.",
  "Huaneng Zhongdian Changdao Wind Power Co. Ltd",
  "Steel Authority Of India Limited",
  "Aydem Yenilenebilir Enerji A.Ş.",
  "Tieling Longyuan Wind Power Co., Ltd.",
  "Gansu Jieyuan Wind Power Co., Ltd.",
  "Gansu Xinan Wind Power Co., Ltd.",
  "CECIC HKC Gansu Wind Power Co., LTD.",
  "C-Quest Capital Malaysia Limited",
  "Sinohydro 14th Bureau Dali Juneng Investment Co.Lt",
  "Heilongjiang Huafu Mulan Wind power Co., Ltd.",
  "Food and Trees for Africa (FTFA)",
  "Multiple Proponents",
  "Guangdong Huizhou LNG Power Co., Ltd.",
  "BLA COKE PRIVATE LIMITED",
  "Multiple Proponents",
  "Guangdong Yudean Shibeishan Wind Power Development",
  "TGT Enerji Üretim ve Tic. A.Ş.",
  "CECIC HKE Wind Power CO.,LTD",
  "CECIC Wind-power (Gansu) Co., Ltd.",
  "Multiple Proponents",
  "Guizhou Qiannan Zhongshui Hydro Power Development",
  "RESURGE ENERGY PRIVATE LIMITED",
  "RESURGE ENERGY PRIVATE LIMITED",
  "Multiple Proponents",
  "Gujarat Ambuja Exports Limited",
  "Patnaik Minerals Private Limited (PMPL)",
  "Patnaik Minerals Private Limited (PMPL)",
  "Construction Investment Yanshan Wind Energy",
  "Clean Air Action Corporation",
  "Multiple Proponents",
  "Multiple Proponents",
  "Basin Electric Power Cooperative",
  "Multiple Proponents",
  "Multiple Proponents",
  "Multiple Proponents",
  "Multiple Proponents",
  "Multiple Proponents",
  "DEGIRMENUSTU ENERJI URETIM TICARET VE SANAYI AS",
  "Multiple Proponents",
  "Multiple Proponents",
  "Rohan Builders (India)",
  "Ruchi Infrastructure Limited",
  "Ruchi Soya Industries Limited",
  "Multiple Proponents",
  "'SANKO Enerji Sanayi ve Ticaret A.Ş",
  "Lichuan Yu River Hydropower Co., Ltd.",
  "Elen Enerji Uretimi Sanayi Ticaret A.S.",
  "Multiple Proponents",
  "Enercon (India) Limited",
  "Elen Enerji Uretimi Sanayi Ticaret A.S.",
  "Elen Enerji Uretimi Sanayi Ticaret A.S.",
  "Liaoyuan Jingang Cement (Holding) Co., Ltd.",
  "Sara Textiles Ltd.",
  "Yeni Doruk Enerji Elektrik Uretim A.S.",
  "Limak Yatirim Enerji Uretim Isletme Hizmetleri",
  "Elen Enerji Uretimi Sanayi Ticaret A.S.",
  "Maguan Laqi Power Generation Co., Ltd.",
  "Multiple Proponents",
  "NHPC Ltd",
  "Wuhe Kaidi Green Energy Development Co., Ltd",
  "Dalmia Bharat Sugar and Industries Limited",
  "United Plantations Berhad",
  "Sichuan Daduhe Power Co., Ltd",
  "Arvind Construction Private Limited",
  "Enerjisa Enerji Uretim A.S.",
  "Mrs. Revathi Raju",
  "Multiple Proponents",
  "Dynegy Inc.",
  "Mercer Vu Farms, Inc.",
  "Maine Housing",
  "Loyal Textiles Mills Ltd.",
  "Enbati Elektrik Uretim Sanayi ve Ticaret A.S",
  "Tongcheng Kaidi Green Energy Development Co., Ltd.",
  "EcoSecurities International Limited",
  "Dempsey Ridge Wind Farm, LLC",
  "Arbec Forest Products G.P.",
  "Super Wind Project Private Ltd",
  "Bangkok Green Power Co., Ltd.",
  "Multiple Proponents",
  "Zenith Green Energy Co., Ltd",
  "Multiple Proponents",
  "eThekweni municipality cleansing & solid waste",
  "Shenzhen Guangqian Electric Power Co., Ltd.",
  "China Resources Wind Power Inner Mongolia Bayinxil",
  "Energy Mad Limited",
  "CLP Wind Farms (Khandke) Private Limited",
  "CLP Wind Farms (Khandke) Private Limited",
  "Quzhou Tadi Hydro Complex Development Co., Ltd.",
  "China Resources Wind Power (Huilai) Co., Ltd.",
  "China Resources Windpower Development (Shantou Haojiang) Co., Ltd.",
  "Dalmia Bharat Sugar and Industries Limited",
  "Pur Projet",
  "Jingshan Kaidi Green Energy Development Co., Ltd.",
  "China Resources Wind Power (Huilaizhoutian) Co., Ltd.",
  "Busoga Forestry Co. Ltd (Subsidiary Green Resources)",
  "M/s D. J. Malpani",
  "Greenville Gas Producers, LLC",
  "Multiple Proponents",
  "Rake Power Limited",
  "Multiple Proponents",
  "Multiple Proponents",
  "Climate Change Capital Carbon Fund S.a.r.l.",
  "Godawari Power and Ispat Ltd.",
  "Yeşilbas Elektrik Üretim AŞ",
  "Chongli Construction Investment Huashi Wind Energy Co., Ltd",
  "Hebei Construction Investment Yuzhou Wind Energy Co., Ltd.",
  "AL-Wind Energy",
  "Ceramic Sao Judas Tadeu Ltda",
  "Enova Enerji Üretim A.Ş.",
  "Multiple Proponents",
  "Bull Run Overseas Ltd.",
  "Chifeng Xinsheng Wind Power Co., Ltd",
  "Hebei Construction Investment Yuzhou Wind Energy Co., Ltd.",
  "Chifeng Xinsheng Wind Power Co., Ltd.",
  "Batısoke Soke Çimento San. T.A.S.",
  "GEPIC Darong Electric Power Company Ltd.",
  "Fermin Aldabe",
  "Hidroeléctrica Río Lircay S.A.",
  "Papop Renewable Company Limited",
  "Native Energy, Inc.",
  "SRF Limited",
  "Baticim Bati Anadolu Cimento Sanayii A.S.",
  "GEPIC Darong Electric Power Company Ltd.",
  "Clean Air Action Corporation",
  "Clean Air Action Corporation",
  "Clean Air Action Corporation",
  "Clean Air Action Corporation",
  "Multiple Proponents",
  "Multiple Proponents",
  "Multiple Proponents",
  "Beijing Jingneng Clean Energy Co., Ltd.",
  "Consorcio Eólico Amayo (Fase II) S.A.",
  "CBNS NEGÓCIOS FLORESTAIS S/A",
  "County of Frederick Public Works Department",
  "Fulton County Department of Solid Waste",
  "Ruchi Soya Industries Limited",
  "Song Ong Hydropower Joint Stock Company",
  "Konaseema Gas Power Ltd",
  "The Nature Conservancy",
  "The Nature Conservancy",
  "Beijing Jingeng Energy Technology Investment Compa",
  "Multiple Proponents",
  "Maharashtra State Power Generation Company Limited",
  "Friends Salt Works &Allied Industries",
  "Que Phong Hydropower Joint Stock Company",
  "Multiple Proponents",
  "Brascarbon Cosultoria Projetos e Represetacao SA",
  "Brascarbon Cosultoria Projetos e Represetacao SA",
  "Brascarbon Cosultoria Projetos e Represetacao SA",
  "SinoHydro CLP Wind Power Co., Ltd",
  "Beijing International New Energy Co., Ltd.",
  "Yanbian Heli Electric Power Co., Ltd.",
  "Beijing International New Energy Co., Ltd.",
  "North-West Electric Investment & Development JSC",
  "Programme for Belize",
  "UIC UDYOG LIMITED,KOLKATA",
  "Alexandria Carbon Black Co. S.A.E.",
  "Gupta Coalfields & Washeries Limited",
  "Anthrotect S.A.S.",
  "Multiple Proponents",
  "Multiple Proponents",
  "Bataan 2020, Inc",
  "Yanbian Heli Electric Power Co., Ltd.",
  "AES AgriVerde Ltd.",
  "AES AgriVerde Ltd.",
  "AES AgriVerde Ltd.",
  "AES AgriVerde Ltd.",
  "AES AgriVerde Ltd.",
  "AES AgriVerde Ltd.",
  "AES AgriVerde Ltd.",
  "AES AgriVerde Ltd.",
  "Bosques Amazónicos",
  "EDCL Power Projects Limited",
  "Yanbian Heli Electric Power Co., Ltd.",
  "M/s Chhotabhai Jethabhai Patel & Co.",
  "Vestas Wind Technology India Private Limited",
  "ClimeCo LLC",
  "Longyuan (Jiuquan) Wind Power Co., Ltd.",
  "Metropolitan Institute PROTRANSPORTE of Lima",
  "Caragua",
  "Shree Nakoda Ispat Ltd",
  "Sichuan Huadian Xixi River Hydroelec. Dev. Co., Lt",
  "Brascarbon Cosultoria Projetos e Represetacao SA",
  "Native Energy, Inc.",
  "Guizhou Anshun Sanchawan Hydropower Co., Ltd.",
  "PC3 - Investment Joint Stock Company",
  "JPMorgan Ventures Energy Corportaion",
  "Sichuan Huadian Xixi River Hydroelec. Dev. Co., Lt",
  "Honghe Guangyuan Madushan Hydropower Development Co., Ltd",
  "Honghe Guangyuan Hydro Power Development Co.Ltd.",
  "M/s. A.A. Energy Limited",
  "Agrícola y Forestal SNP Ltda",
  "Hightrack International (Hong Kong) Technology Lim",
  "Armenis Farm Ltd.",
  "Brascarbon Cosultoria, Projetos e Represetacao Ltd",
  "Xinjiang Tianfeng Wind Power Co., Ltd.",
  "Chifeng Xinsheng Wind Power Co., Ltd.",
  "Savita Oil Technologies Limited",
  "Hebei Construction Investment New Energy Co., Ltd.",
  "Kale Enerji Üretim Ticaret Sanayi A.S.",
  "Yunnan Dianneng Sinanjiang Hydropower Development",
  "Multiple Proponents",
  "Foz do Chapecó Energia S.A.",
  "Multiple Proponents",
  "Bio Energy Resources Limited (BERL)",
  "Clean Air Action Corporation",
  "TerraPass",
  "Essent Trading",
  "Za Hung Joint Stock Company",
  "Energía Provincial Sociedad del Estado (EPSE)",
  "Carbon Green Investments (Guernsey)",
  "Hebei Construction Investment New Energy Co., Ltd.",
  "Royal Government of Cambodia-Forestry Admin",
  "'SANKO Enerji Sanayi ve Ticaret A.Ş",
  "Datang Zhangzhou Wind Power Co. Ltd",
  "Wilmar International Limited",
  "Wilmar International Limited",
  "CLP Wind Farms (India) Private Limited",
  "Yichang Changfeng Hydropower Development Co., Ltd",
  "CLP Wind Farms (India) Private Limited",
  "CLP Wind Farms (Theni - Project II) Private Limited",
  "Arik Enerji Uretim A.S.",
  "Siam Quality Starch Co., Ltd.",
  "YPM Elektrik Uretim A.p",
  "Multiple Proponents",
  "Slate Ridge Dairy Farm, Inc.",
  "Aslancik Elektrik Üretim A.S.",
  "KAREN Kahramanmaraş Elektrik Üretim AŞ",
  "The Nature Conservancy",
  "Jaiprakash Hydro Power Limited",
  "Los Eucaliptus S.A.",
  "Beijing International New Energy Co., Ltd.",
  "Sun Plant Agro Limited",
  "TVS Energy Limited",
  "Tuppadahalli Energy India Pvt. Ltd.",
  "D. J Malpani",
  "Sargam Retails Pvt. Limited",
  "Sargam Retails Pvt. Limited",
  "Datang Mangli Hydropower Development Company Limit",
  "Will Solutions Inc",
  "Malana Power Company Limited",
  "TVS Energy Limited",
  "Velatal Spinning Mills (Pvt) Limited (VSMPL)",
  "Kalen Enerji Elektrik Uretim A.S.",
  "VELATAL SPINNING MILLS PRIVATE LIMITED",
  "Wildlife Works Carbon LLC",
  "TAKE BACK THE FUTURE CC",
  "Guizhou Zhongshui Energy Co.Ltd.",
  "Southern Ute Indian Tribe Growth Fund Department",
  "Multiple Proponents",
  "Les Serres du St-Laurent Inc",
  "Jiangsu Qingshi Cement Co. Ltd.",
  "Multiple Proponents",
  "co2balance Ltd",
  "William Charles Energy, LLC",
  "Multiple Proponents",
  "Conservation International Foundation",
  "Multiple Proponents",
  "Shoosmith Brothers Inc",
  "Multiple Proponents",
  "Kosmos Cement Company",
  "Guodian Linghai Wind Power Co., Ltd",
  "Heilongjiang Huafu Muling Wind Power Co. Ltd",
  "Guodian Linghai Wind Power Co., Ltd",
  "Guodian Linghai Wind Power Co., Ltd",
  "Guodian Xingcheng Wind Power Co., Ltd",
  "Swire Pacific Offshore Operations (Pte) Ltd. (SPO)",
  "ENTEK",
  "Multiple Proponents",
  "Animalia Genetics Ltd.",
  "Multiple Proponents",
  "Pur Projet",
  "Guanaré SA",
  "Shandong Yuchen Xinyuan Heat & Power Co., Ltd.",
  "EUCAPINE S.R.L",
  "Forestal El Arriero SRL",
  "Multiple Proponents",
  "Multiple Proponents",
  "Karadeniz Hes Elektrik Anonim Şirketi",
  "Intercontinental Timber Asociacion Agraria",
  "Biogas Technology Ltd",
  "PT Navigat Organic Energy Indonesia",
  "Renewable Power Sdn. Bhd.",
  "China Resources Wind Power (Qingdao) Co., Ltd.",
  "Hunan Zhongda Shendu Hydropower Co., Ltd.",
  "China Resources Wind Power (Fuxin) Co., Ltd.",
  "Tay Nguyen Hydropower Company Limited",
  "Green Infra Wind Energy Limited",
  "Multiple Proponents",
  "DSL HYDROWATT PRIVATE LIMITED",
  "Chongqing Bashan Hydropower Development Co., Ltd.",
  "Huaneng Turpan Wind Power Co., Ltd.",
  "RMDLT Property Group Ltd.",
  "Taini (Guang'an) Cement Co.,Ltd",
  "Taini (Anshun) Cement Co.,Ltd",
  "Pamir Energy Company",
  "Multiple Proponents",
  "Avoided Deforestation Project (Manaus) Limited (“A",
  "GVK Industries Limited",
  "Vitol SA",
  "CEMEX Construction Materials of Florida",
  "CIMA, Cordillera Azul",
  "ENTEK",
  "Form Ghana Ltd",
  "Orient Bell Limited",
  "CEMEX Construction Materials of Florida",
  "Datang Chifeng Saihanba Wind Power Generation Co.,",
  "Multiple Proponents",
  "TCC Liaoning Cement Company Limited",
  "Sibanye Gold Ltd",
  "Clean Air Action Corporation",
  "Clean Air Action Corporation",
  "Clean Air Action Corporation",
  "Clean Air Action Corporation",
  "Guodian Xinjiang Kaidu River Valley Hydro-power",
  "Vietnam Electricity (EVN)",
  "Redd Services",
];
